import React, { useEffect, useState } from 'react'
import { Button, Card, Elevation, HTMLTable, Icon, Spinner, Tag } from '@blueprintjs/core'
import { AppToaster } from '../../../utilities/Toaster/Toaster'

import CreateWorker from './actions/CreateWorker'

const TerminalWorkers = () => {
    const [isLoad, setIsLoad] = useState(false)
    const [allWorkers, setAllWorkers] = useState([])
    const [workerIdForDelete, setWorkerIdForDelete] = useState('')
    const [shouldUpdate, setShouldUpdate] = useState(false)

    useEffect(() => {
        fetch('/api/workers/getAllWorkers', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => setAllWorkers(data.result))
    }, [shouldUpdate])

    const callback = () => setShouldUpdate(!shouldUpdate)

    const handleRemoveWorker = (workerId) => {
        setWorkerIdForDelete(workerId)
        setIsLoad(true)
        fetch(`/api/workers/removeWorkerById/${workerId}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } })
            .then(() => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Worker deleted!' })
                setShouldUpdate(!shouldUpdate)
                setWorkerIdForDelete('')
                setIsLoad(false)
            })
    }

    return (
        <Card elevation={Elevation.FOUR} interactive={false} className='card-terminal-workers'>
            <h4 style={{ marginTop: '0px' }}>Manage Terminal Workers</h4>
            <div className='terminal-action-buttons'>
                <CreateWorker callback={callback} />
            </div>
            <HTMLTable className='terminal-htmltable' bordered={true} striped={true} interactive={false} compact={true}>
                <thead>
                    <tr className='terminal-thead-tr'>
                        <th className='thead-th'>#</th>
                        <th className='thead-th' style={{ width: '20%' }}>Terminal Id</th>
                        <th className='thead-th'>Terminal Name</th>
                        <th className='thead-th'>Status</th>
                        <th className='thead-th'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {allWorkers.map((worker, index) => {
                        const { workerId, workerName, workerStatus } = worker
                        return <tr key={index}>
                            <td style={{ textAlign: 'center' }}>{index + 1}</td>
                            <td style={{ textAlign: 'center', color: '#3498DB' }}>{workerId}</td>
                            <td style={{ textAlign: 'left' }}><Icon icon='build' intent='warning' /> {workerName}</td>
                            <td style={{ textAlign: 'center', width: '20%' }}>
                                {workerStatus !== 'Occupied'
                                    ? <Tag fill={true} style={{ height: '25px' }} intent='success' minimal={true}><Icon icon='updated' /> {workerStatus}</Tag>
                                    : <Tag fill={true} style={{ height: '25px' }} intent='warning' minimal={true}><Icon icon='warning-sign' /> {workerStatus}</Tag>}
                            </td>
                            <td style={{ textAlign: 'center', width: '5%' }}>
                                <Button
                                    small={false}
                                    intent='danger'
                                    onClick={() => handleRemoveWorker(workerId)}
                                    icon={ isLoad && workerId === workerIdForDelete ? <Spinner size={12} /> : 'trash' }
                                    disabled={ isLoad && workerId === workerIdForDelete ? true : false }
                                />
                            </td>
                        </tr>
                    })}
                </tbody>
            </HTMLTable>
        </Card>
    )
}

export default TerminalWorkers