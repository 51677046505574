import { Card, HTMLTable, Tag, Button, Icon, PopoverInteractionKind, Position, ButtonGroup, Elevation } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import React, { useState } from 'react'
import DeleteUser from './sub/DeleteUser'
import DeactivateUser from './sub/DeactivateUser'

const CurrentUsers = ({ allUsers, callback, currentUserDetails }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString)
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const year = date.getFullYear()
      
        return `${month}/${day}/${year}`
    }

    return (
        <Card className='all-users-card' elevation={Elevation.FOUR}>
            <HTMLTable className='all-users-card-table' bordered={true} striped={true} interactive={true} compact={true}>
                <thead className='all-user-thead'>
                    <tr>
                        <th className='all-user-th' style={{ width: '5%' }}>Role</th>
                        <th className='all-user-th' style={{ width: '15%' }}>First Name</th>
                        <th className='all-user-th' style={{ width: '15%' }}>Last Name</th>
                        <th className='all-user-th' style={{ width: '25%' }}>Email</th>
                        <th className='all-user-th' style={{ width: '10%' }}>Birthday</th>
                        <th className='all-user-th' style={{ width: '7%' }}>Gender</th>
                        <th className='all-user-th' style={{ width: '10%' }}>Phone</th>
                        <th className='all-user-th' style={{ width: '10%' }}>Status</th>
                        <th className='all-user-th' style={{ width: '3%' }}></th>
                    </tr>
                </thead>
                <tbody className='all-user-tbody'>
                    { allUsers.map((user, userIndex) => (
                        <tr key={userIndex}>
                            <td className='all-user-tbody-others'><Tag intent={user.role==='admin'?'primary':'warning'}>{user.role}</Tag></td>
                            <td className='all-user-tbody-name'>{user.firstname}</td>
                            <td className='all-user-tbody-name'>{user.lastname}</td>
                            <td className='all-user-tbody-email'><Tag minimal>{user.email}</Tag></td>
                            <td className='all-user-tbody-others'>{formatDate(user.birthday)}</td>
                            <td className='all-user-tbody-others'>{user.gender}</td>
                            <td className='all-user-tbody-others'>{user.phone}</td>
                            <td className='all-user-tbody-others'><Tag intent={user.isActive?'success':'danger'}>{user.isActive ? 'Active' : 'Inactive'}</Tag></td>
                            <td>
                                <Popover2
                                    interactionKind='click'
                                    placement="right"
                                    content={
                                        <ButtonGroup vertical minimal>
                                            <DeactivateUser user={user} callback={callback} currentUserDetails={currentUserDetails}/>
                                            <DeleteUser user={user} callback={callback} currentUserDetails={currentUserDetails}/>
                                        </ButtonGroup>
                                    }
                                    renderTarget={({ isOpen, ref, ...targetProps }) => (
                                        <Button {...targetProps} elementRef={ref} icon='more' minimal/>
                                    )}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </HTMLTable>
        </Card>
    )
}

export default CurrentUsers