import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Navbar, Button, Alignment, ButtonGroup, Divider } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import banner from './banner-white.png'
import favicon from './Favicon.png'

import './Header.css'

const Header = () => {
    const navigate = useNavigate()

    const handleLogout = () => {
        localStorage.clear('jwt')
        localStorage.clear('id')
        localStorage.clear('temp')
        navigate('/')
    }

    return (
        <header className='header'>
            <Navbar fixedToTop className='navbar'>
                <Navbar.Group align={Alignment.LEFT}>
                   <Navbar.Heading className='navbar-heading'>
                        <img src={favicon} alt='Default Avatar' className='image-banner-logo' />
                        <img src={banner} alt='Default Avatar' className='image-banner' />
                    </Navbar.Heading>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <div className='version-label'>Version 1.1</div>
                    <span className="bp4-navbar-divider"></span>
                    <Button className="bp4-minimal" icon="notifications" intent='primary' disabled/>
                    <Popover2
                        interactionKind='click'
                        placement='bottom-end'
                        content={
                            <ButtonGroup vertical={true} alignText='left'>
                                {/* <Button className="bp4-minimal" icon="mugshot" text='My Account' />
                                <Divider /> */}
                                <Button className="bp4-minimal" icon="log-out" text='Logout' intent='danger' onClick={handleLogout} />
                            </ButtonGroup>
                        }
                    >
                        <Button className="bp4-minimal" icon="user" intent='primary' />
                    </Popover2>
                </Navbar.Group>
            </Navbar>
        </header>
    )
}

export default Header