import React, { useState, useEffect } from 'react'
import { NavLink, useParams, useSearchParams } from 'react-router-dom'
import { Button, Card, Divider, Elevation, HTMLTable, Icon, InputGroup } from '@blueprintjs/core'

import './ManageWorkspace.css'

import Layout from '../../../components/Layout/Layout'
import DeleteWorkbook from '../workspace/DeleteWorkspace'
import DeleteSheet from '../sheets/DeleteSheet'

const ManageWorkspace = () => {
    const params = useParams()
    const [queryParams] = useSearchParams()
    const bookName = queryParams.get('bookName')
    const bookId = params.id

    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [sheets, setSheets] = useState([])

    useEffect(() => {
        fetch(`/api/jobs/getSheets/${bookId}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => setSheets(data.sheets))
    }, [shouldUpdate])

    return (
        <Layout>
            <div>
                <span className='manage-settings-title'>Manage Workspace</span>
                <NavLink to='/dashboard'>
                    <Button icon='circle-arrow-left' style={{ float: 'right' }}>Go Back</Button>
                </NavLink>
            </div>

            <h3>General</h3>
            <Card className='manage-settings-card general' elevation={Elevation.FOUR} interactive={false}>
                <span className='settings-card-title'>Workspace Name:</span>
                <InputGroup className='settings-card-description' value={bookName} disabled={true} />

                <span className='settings-card-title'>Workspace ID:</span>
                <InputGroup className='settings-card-description' value={bookId} disabled={true} />
            </Card>

            <h3>Danger Zone</h3>
            <Card className='manage-settings-card danger-card' elevation={Elevation.FOUR} interactive={false} >
                <div>
                    <DeleteWorkbook bookData={{ bookName: bookName, bookId: bookId }} />
                    <span className='settings-card-title danger'>Delete this workspace</span>
                    <p className='settings-card-description'><Icon intent='danger' icon='error' /> Once you delete a workspace, there is no going back. Please be certain.</p>
                </div>
                <Divider className='settings-danger-divider' />
                <div>
                    <span className='settings-card-title'>Active Sheets</span>
                    <HTMLTable className='table-active-sheets' bordered={true} striped={true} interactive={true} compact={true}>
                        <thead>
                            <tr className='tr-active-sheets'>
                                <th style={{ width: '5%' }}>#</th>
                                <th>Sheet Name</th>
                                <th style={{ width: '20%' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sheets.length > 0 ?
                                sheets.map((sheet, index) => (
                                    <tr>
                                        <td style={{ fontSize: '12px' }}>{index + 1}</td>
                                        <td style={{ fontSize: '12px' }}>{sheet.sheetName}</td>
                                        <td>
                                            <DeleteSheet sheetId={sheet.sheetId} bookId={bookId} sheetName={sheet.sheetName} callback={() => setShouldUpdate(!shouldUpdate)} />
                                        </td>
                                    </tr>
                                )) : <tr><td colSpan={4} style={{ textAlign: 'center', fontSize: '12px', color: 'grey' }}>No registered sheets on this workbook yet.</td></tr>}
                        </tbody>
                    </HTMLTable>
                </div>
            </Card>
        </Layout>
    )
}

export default ManageWorkspace