import React, { useEffect, useState } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import { Card, Elevation, ButtonGroup, HTMLTable, HTMLSelect, ProgressBar, Tag, Icon, Spinner, Button } from '@blueprintjs/core'
import { Breadcrumbs2, Popover2 } from "@blueprintjs/popover2"
import { AppToaster } from '../../../utilities/Toaster/Toaster'

import Layout from '../../../components/Layout/Layout'
import AddTrigger from '../triggers/AddTrigger'
import NewTask from '../tasks/NewTask'
import DropTask from '../tasks/DropTask'
import RunTrigger from '../triggers/RunTrigger'
import DeleteTrigger from '../triggers/DeleteTrigger'
import AddSheet from '../sheets/AddSheet'
import TriggerSwitch from './sub/TriggerSwitch'
import RankInput from './sub/RankInput'
import DescriptionInput from './sub/DescriptionInput'
import SourceRangeInput from './sub/SourceRangeInput'
import SourceSelect from './sub/SourceSelect'
import DestinationRangeInput from './sub/DestinationRangeInput'
import RunOptions from './sub/RunOptions'
import SwitchOptions from './sub/SwitchOptions'
import ResetTrigger from '../triggers/ResetTrigger'
import TaskScheduler from './sub/scheduler/TaskScheduler'

import '../jobs/Jobs.css'

const Jobs = () => {
    const defaultTimer = 15
    const params = useParams()
    const [refreshTimer, setRefreshTimer] = useState(defaultTimer)
    const [queryParams] = useSearchParams()
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [shouldUpdateCount, setShouldUpdateCount] = useState(0)
    const [sheets, setSheets] = useState([])
    const [selectedSheetId, setSelectedSheetId] = useState('')
    const [selectedTaskId, setSelectedTaskId] = useState('')
    const [checkChangesDescription, setCheckChangesDescription] = useState(false)
    const [checkChangesSourceRange, setCheckChangesSourceRange] = useState(false)
    const [checkChangesDestinationRange, setCheckChangesDestinationRange] = useState(false)
    const [selectedTrigger, setSelectedTrigger] = useState({})
    const [selectedTriggerIndex, setSelectedTriggerIndex] = useState('')
    const [initialRender, setInitialRender] = useState(true)
    const [latestSheetId, setLatestSheetId] = useState('')
    const [selectedTaskStatus, setSelectedTaskStatus] = useState('')

    useEffect(() => {
        setSelectedSheetId(latestSheetId)
    }, [initialRender])

    useEffect(() => {
        fetch(`/api/jobs/getWorkspaceById/${params.id}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                const sheets = data.result.sheets
                setSheets(sheets)
                
                if (selectedSheetId === '' && sheets.length > 0) {
                    setSelectedSheetId(sheets[0]?.sheetId)
                    let firstTask = sheets[0]?.tasks[0]

                    if (firstTask) {
                        setSelectedTaskId(firstTask.taskId)
                        setSelectedTaskStatus(firstTask.taskStatus)
                    } else {
                        setSelectedTaskId('')
                        setSelectedTaskStatus('')
                    }
                } else {
                    sheets.map(item => {
                        if (item.sheetId === parseInt(selectedSheetId)) {
                            if (selectedTaskId === '') {
                                setSelectedTaskId(item.tasks[0].taskId)
                                setSelectedTaskStatus(item.task[0].taskStatus)
                            }
                        }
                    })
                }
            })
    }, [shouldUpdate, shouldUpdateCount])

    useEffect(() => {
        if(refreshTimer<1) {
            callback()
            setRefreshTimer(defaultTimer)
        }
        const timer = refreshTimer >= 0 && 
            setInterval(() => {
                setRefreshTimer(refreshTimer - 1)
            }, 1000)
        return () => clearInterval(timer)
    }, [refreshTimer])

    const callback = () => setShouldUpdate(!shouldUpdate)
    const callback2 = () => setShouldUpdateCount(prevCount => prevCount + 1)

    const multiCallback = (newSheetId) => {
        setLatestSheetId(newSheetId)
        setInitialRender(!initialRender)
        setShouldUpdate(!shouldUpdate)
    }

    const handleChangeSelectedSheet = (event) => {
        setSelectedSheetId(event.target.value)
    
        let sheetData = sheets.find((data) => data.sheetId === parseInt(event.target.value))
        if (sheetData.tasks[0]) {
            setSelectedTaskId(sheetData.tasks[0].taskId)
            setSelectedTaskStatus(sheetData.tasks[0].taskStatus)
        } else {
            setSelectedTaskId('')
            setSelectedTaskStatus('')
        }
    }

    const handleChangeSelectedTask = (event) => setSelectedTaskId(event.target.value)

    const handleClearSource = (trigger, bookId, sheetId, taskId) => {
        let triggerData = trigger
        triggerData.source = { tag: '', main: '', sub: '' }
        fetch('/api/jobs/updateTrigger', { method: 'PUT', body: JSON.stringify({ bookId, sheetId, taskId, triggerId: triggerData.triggerId, triggerData }), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(() => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Source updated!' })
                callback()
            })
    }

    const calcTotalPercentage = (sheets) => {
        const result = sheets.find(sheet => sheet.sheetId === parseInt(selectedSheetId))
        const result2 = result?.tasks.find(task => task.taskId === selectedTaskId)
        const triggers = result2?.triggers || []
      
        const notDisabledTriggers = triggers.filter(trigger => trigger.status !== 'Disabled')
        const completedTriggers = notDisabledTriggers.filter(trigger => trigger.status === 'Completed')
        const percentage = notDisabledTriggers.length ? completedTriggers.length / notDisabledTriggers.length : 0
      
        return percentage
    }

    return (
        <Layout>
            <h4 className='addsheet-button'>
                <div className='book-name'>{queryParams.get("bookName")}</div>
                <ButtonGroup className='addsheet-button2'>
                    <AddSheet bookId={params.id} callback={multiCallback} />
                </ButtonGroup>
            </h4>
            <ProgressBar
                className='task-progress-bar'
                intent='success'
                animate={false}
                stripes={false}
                value={calcTotalPercentage(sheets)}
            />
            <Card elevation={Elevation.FOUR}>
                { sheets.length === 0 ? <p className='empty-sheet'>Please Add a Sheet</p> :
                <div className='card-container'>
                    <div className='card-wrapper'> 
                        <span className='timer-span'>Auto-refresh in <b>{refreshTimer}</b> seconds.</span>
                        <div className='task-sheet-tools'>
                            <div className='task-sheet-name'>
                                <span>
                                    <HTMLSelect fill={true} style={{ fontSize: '12px', textAlign: 'center', width: '250px' }}
                                        value={selectedSheetId}
                                        onChange={handleChangeSelectedSheet}
                                    >
                                        {sheets.map((sheet, index) => (
                                            <option key={index} value={sheet.sheetId}>{sheet.sheetName}</option>
                                        ))}
                                    </HTMLSelect>
                                </span>
                                <span className='btn-sheets'>
                                    <ButtonGroup>
                                        <SwitchOptions 
                                            selectedSheetId={selectedSheetId}
                                            selectedTaskId={selectedTaskId}
                                            callback={callback}
                                        />
                                        <RunOptions 
                                            selectedSheetId={selectedSheetId}
                                            selectedTaskId={selectedTaskId}
                                            callback={callback}
                                            sheets={sheets}
                                            callback2={callback2}
                                        />
                                    </ButtonGroup>
                                </span>
                            </div>
                            <div className='btn-triggers'>
                                <AddTrigger sheetId={selectedSheetId} taskId={selectedTaskId} callback={callback}/>
                            </div>
                            <div className='task-sheet-tasks'>
                                <HTMLSelect
                                    fill={true}
                                    className='subfolder-htmlselect'
                                    onChange={handleChangeSelectedTask}
                                    disabled={selectedTaskId === '' ? true : false }
                                    style={{ fontSize: '12px', textAlign: 'center' }}
                                >
                                    {sheets.map(sheet => {
                                        if (sheet.sheetId === parseInt(selectedSheetId)) {
                                            return sheet.tasks.map(task => {
                                                return <option key={task.taskId} value={task.taskId}>{task.taskName}</option>
                                            })
                                        }
                                    })}
                                </HTMLSelect>
                            </div>
                            <div className='btn-tasks'>
                                <ButtonGroup alignText='center'>
                                    <NewTask bookId={params.id} sheetId={selectedSheetId} callback={callback} />
                                    <DropTask bookId={params.id} sheetId={selectedSheetId} taskId={selectedTaskId} callback={callback} />
                                </ButtonGroup>
                            </div>
                        </div>
                        <Card className='card-sheet2' elevation={Elevation.FOUR}>
                            <div className='card-sheet2b'>
                                {sheets.map(sheet => {
                                    if (sheet.sheetId === parseInt(selectedSheetId)) {
                                        return sheet.tasks.map(task => {
                                            if (task.taskId === selectedTaskId && (task.taskStatus === 'On-going' || task.taskStatus === 'Open')) {
                                                return <div>
                                                    <div className='spinner-cover'></div>
                                                    <Spinner intent='warning' className='trigger-spinner' />
                                                </div>
                                            }
                                        })
                                    }
                                })}
                                <HTMLTable className='html-table1' bordered={true} striped={true} interactive={true} compact={true}>
                                    <thead>
                                        <tr className='thead-tr'>
                                            <th className='thead-th' style={{ width: '4%' }}>Enable</th>
                                            <th className='thead-th' style={{ width: '7%' }}>Priority</th>
                                            <th className='thead-th' style={{ width: '10%' }}>Trigger</th>
                                            <th className='thead-th' style={{ width: '20%' }}>Description</th>
                                            <th className='thead-th' style={{ width: '16%' }}>Source</th>
                                            <th className='thead-th' style={{ width: '16%' }}>Source Range <Icon intent='warning' icon='function' /> <Icon intent='warning' icon='list-detail-view' /></th>
                                            <th className='thead-th' style={{ width: '16%' }}>Destination Range</th>
                                            <th className='thead-th' style={{ width: '6%' }}>Status</th>
                                            <th className='thead-th' style={{ width: '5%' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { sheets.map((sheet, sheetIndex) => {
                                            if (sheet.sheetId === parseInt(selectedSheetId)) {
                                                if (sheet.tasks.length === 0 ) { return <tr className='no-jobs' key={sheetIndex}><td colSpan="9"><div className='no-jobs-div'>No Triggers</div></td></tr> }
                                                return sheet.tasks.map((task, taskIndex) => {
                                                    if (task.taskId === selectedTaskId) {
                                                        if (task.triggers.length === 0 ) { return <tr className='no-jobs' key={taskIndex}><td colSpan="9"><div className='no-jobs-div'>No Triggers</div></td></tr> }
                                                        return task.triggers.map((trigger, triggerIndex) => {
                                                            const { main, sub } = trigger.source
                                                            return <tr className='tr1' key={triggerIndex}> 
                                                                <td><div className='td-checkbox'>
                                                                    <TriggerSwitch
                                                                        trigger={trigger}
                                                                        sheetId={sheet.sheetId}
                                                                        taskId={task.taskId}
                                                                        callback={callback}
                                                                    />
                                                                </div></td>
                                                                <td><div>
                                                                    <RankInput
                                                                        trigger={trigger}
                                                                        sheetId={sheet.sheetId}
                                                                        taskId={task.taskId}
                                                                        callback={callback}
                                                                    />
                                                                </div></td>
                                                                <td><div>
                                                                    <span style={{ marginTop: '0px', fontWeight: 'bold', color: !trigger.isEnable ? 'grey' : '' }}>{trigger.triggerName}</span>
                                                                </div></td>
                                                                <td><div>
                                                                    <DescriptionInput
                                                                        trigger={trigger}
                                                                        sheetId={sheet.sheetId}
                                                                        taskId={task.taskId}
                                                                        callback={callback}
                                                                        checkChangesDescription={checkChangesDescription} setCheckChangesDescription={setCheckChangesDescription}
                                                                        selectedTrigger={selectedTrigger} setSelectedTrigger={setSelectedTrigger}
                                                                        selectedTriggerIndex={triggerIndex} setSelectedTriggerIndex={setSelectedTriggerIndex}
                                                                    />
                                                                </div></td>
                                                                <td><div style={{ textAlign: 'left' }}>
                                                                    {trigger.source.tag === ""
                                                                    ?   <SourceSelect
                                                                            bookId={params.id}
                                                                            sheetId={sheet.sheetId}
                                                                            taskId={task.taskId}
                                                                            trigger={trigger}
                                                                            callback={callback}
                                                                        />
                                                                    :   <Popover2
                                                                            interactionKind='click'
                                                                            placement="bottom"
                                                                            minimal={true}
                                                                            content={
                                                                                <Card className='card-source'>
                                                                                    {trigger.source.tag !== 'FM'
                                                                                        ?   <Breadcrumbs2
                                                                                                className='bread-source'
                                                                                                items={[
                                                                                                    { icon: "panel-table", text: main.bookName },
                                                                                                    { icon: "document", text: sub.sheetName },
                                                                                                ]}
                                                                                            />
                                                                                        :   <Breadcrumbs2
                                                                                                className='bread-source'
                                                                                                items={[
                                                                                                    { icon: "function", text: main }
                                                                                                ]}
                                                                                            />
                                                                                        }
                                                                                </Card>
                                                                            }
                                                                            renderTarget={({ isOpen, ref, ...targetProps }) => (
                                                                                trigger.isEnable
                                                                                    ?   <Tag
                                                                                            {...targetProps}
                                                                                            elementRef={ref}
                                                                                            icon={trigger.source.tag !== 'FM' ? <Icon icon='document' intent='success' /> : <Icon icon='function' intent='primary' />}
                                                                                            interactive={true}
                                                                                            intent='none'
                                                                                            minimal={true}
                                                                                            round={false}
                                                                                            fill={true}
                                                                                            style={{ height: '30px' }}
                                                                                            onRemove={() => handleClearSource(trigger, params.id, sheet.sheetId, task.taskId)}
                                                                                        >
                                                                                            {trigger.source.tag !== 'FM'
                                                                                                ?   <span>{sub.sheetName}</span>
                                                                                                :   <span>{main}</span>
                                                                                            }
                                                                                        </Tag>
                                                                                    :   <Tag
                                                                                            {...targetProps}
                                                                                            elementRef={ref}
                                                                                            icon={trigger.source.tag !== 'FM' ? <Icon icon='document' intent='none' /> : <Icon icon='function' intent='none' />}
                                                                                            interactive={false}
                                                                                            intent='none'
                                                                                            minimal={true}
                                                                                            round={false}
                                                                                            fill={true}
                                                                                            style={{ height: '30px', cursor: 'not-allowed', color: 'grey' }}
                                                                                        >
                                                                                            {trigger.source.tag !== 'FM'
                                                                                                ?   <span>{sub.sheetName}</span>
                                                                                                :   <span>{main}</span>
                                                                                            }
                                                                                        </Tag>
                                                                            )}
                                                                        />}
                                                                </div></td>
                                                                <td><div>
                                                                    <SourceRangeInput
                                                                        trigger={trigger}
                                                                        sheetId={sheet.sheetId}
                                                                        taskId={task.taskId}
                                                                        callback={callback}
                                                                        checkChangesSourceRange={checkChangesSourceRange} setCheckChangesSourceRange={setCheckChangesSourceRange}
                                                                        selectedTrigger={selectedTrigger} setSelectedTrigger={setSelectedTrigger}
                                                                        selectedTriggerIndex={triggerIndex} setSelectedTriggerIndex={setSelectedTriggerIndex}
                                                                    />
                                                                </div></td>
                                                                <td><div>
                                                                    <DestinationRangeInput
                                                                        trigger={trigger}
                                                                        sheetId={sheet.sheetId}
                                                                        taskId={task.taskId}
                                                                        callback={callback}
                                                                        checkChangesDestinationRange={checkChangesDestinationRange} setCheckChangesDestinationRange={setCheckChangesDestinationRange}
                                                                        selectedTrigger={selectedTrigger} setSelectedTrigger={setSelectedTrigger}
                                                                        selectedTriggerIndex={triggerIndex} setSelectedTriggerIndex={setSelectedTriggerIndex}
                                                                    />
                                                                </div></td>
                                                                <td><div>
                                                                    <Tag
                                                                        style={{ textAlign: 'center' }}
                                                                        round={true}
                                                                        fill={true}
                                                                        intent={trigger.status === 'Completed' ? 'success' : '' || trigger.status === 'Open' ? 'primary' : ''}
                                                                    >{trigger.status}</Tag>                                                                
                                                                </div></td>
                                                                <td className='action-button-group'><div>
                                                                    <ButtonGroup fill={true}>
                                                                        {trigger.status !== 'Completed' ?
                                                                            <RunTrigger
                                                                                triggerIndex={triggerIndex}
                                                                                mainData={{
                                                                                    bookId: params.id,
                                                                                    sheetId: sheet.sheetId,
                                                                                    taskId: task.taskId,
                                                                                    triggerId: trigger.triggerId
                                                                                }}
                                                                                triggerData={{ 
                                                                                    triggerName: trigger.triggerName,
                                                                                    triggerDescription: trigger.triggerDescription,
                                                                                    status: trigger.status,
                                                                                    rank: trigger.rank,
                                                                                    source: trigger.source,
                                                                                    sourceRange: trigger.sourceRange,
                                                                                    destinationRange: trigger.destinationRange,
                                                                                    isEnable: trigger.isEnable,
                                                                                }} 
                                                                                otherData={{
                                                                                    bookName: queryParams.get("bookName"),
                                                                                    sheetName: sheet.sheetName,
                                                                                    selectedTriggerIndex: selectedTriggerIndex,
                                                                                }}
                                                                                checkChanges={{
                                                                                    checkChangesDescription: checkChangesDescription,
                                                                                    checkChangesSourceRange: checkChangesSourceRange,
                                                                                }}
                                                                                callback={callback}
                                                                            /> :
                                                                            <ResetTrigger
                                                                                trigger={trigger}
                                                                                sheetId={sheet.sheetId}
                                                                                taskId={task.taskId}
                                                                                callback={callback}
                                                                            />
                                                                        }
                                                                        <DeleteTrigger 
                                                                            triggerIndex={triggerIndex}
                                                                            triggerData={{ 
                                                                                name: trigger.triggerName,
                                                                                bookId: params.id,
                                                                                sheetId: sheet.sheetId,
                                                                                taskId: task.taskId,
                                                                                triggerId: trigger.triggerId
                                                                            }} 
                                                                            otherData={{
                                                                                selectedTriggerIndex: selectedTriggerIndex,
                                                                            }}
                                                                            checkChanges={{
                                                                                checkChangesDescription: checkChangesDescription,
                                                                                checkChangesSourceRange: checkChangesSourceRange,
                                                                            }}
                                                                            callback={callback}
                                                                        />
                                                                    </ButtonGroup>
                                                                </div></td>
                                                            </tr>
                                                        })
                                                    }
                                                })
                                            }
                                        })}
                                    </tbody>
                                    <tfoot><tr><td colSpan={9}></td></tr></tfoot>
                                </HTMLTable>
                            </div>
                        </Card>
                    </div>
                </div> }
            </Card>
        </Layout>
    )
}

export default Jobs