import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogBody, DialogFooter, Icon, InputGroup, Label, HTMLSelect } from '@blueprintjs/core'
import { useParams } from 'react-router-dom'
import { AppToaster } from '../../../utilities/Toaster/Toaster'
import * as middlewares from '../../../middlewares/triggers'

import './Triggers.css'

const AddTrigger = ({ sheetId, taskId, callback }) => {
    const dialogInitialState = {
        bookId: '',
        sheetId: '',
        taskId: '',
        trigger: {
            triggerId: '',
            triggerName: '',
            triggerDescription: '',
            status: '',
            rank: 0,
            source: { tag: '', main: '', sub: '' },
            sourceRange: '',
            destinationRange: '',
            isEnable: true,
        }
    }

    const params = useParams()
    const [isLoad, setIsLoad] = useState(false)
    const [triggerNames, setTriggerNames] = useState([])
    const [triggerData, setTriggerData] = useState(dialogInitialState)
    const [showAddTrigger, setShowAddTrigger] = useState(false)

    useEffect(() => {
        let middlewareNames = []
        middlewareNames.push('- Please select trigger name -')
        Object.keys(middlewares).forEach(key => {
            middlewareNames.push(key)
        })

        setTriggerNames(middlewareNames)
    }, [])

    const handleAddTrigger = () => {
        setShowAddTrigger(true)
        setTriggerData({...triggerData, 
            bookId: params.id,
            sheetId: sheetId,
            taskId: taskId,
            trigger: {
                triggerId: 'TR-'+ Date.now(),
                triggerName: '',
                triggerDescription: '',
                status: 'Open',
                rank: 0,
                source: { tag: '', main: '', sub: '' },
                sourceRange: '',
                destinationRange: '',
                isEnable: true,
            }
        })
    }
    
    const handleAddTriggerQuery = () => {
        if (triggerData.trigger.triggerName) {
            setIsLoad(true)
            fetch(`/api/jobs/addTrigger`, { method: 'POST', body: JSON.stringify(triggerData), headers: { 'Content-Type': 'application/json' } })
                .then(res => res.json())
                .then(data => {
                    setTriggerData(dialogInitialState)
                    setShowAddTrigger(false)
                    callback()
                    AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Trigger Added!' })
                    setIsLoad(false)
                }
            )
        } else {
            AppToaster.show({ icon: 'info-sign', intent: 'warning', message: 'Please select a trigger name.' })
        }
    }
    
    return(
        <div className=''>
            <Dialog className='bp4-dark' canEscapeKeyCancel={true} canOutsideClickCancel={true}
                icon={<Icon icon='add-row-bottom'></Icon>}
                title={<div className='dialog-header-label'>Add a Trigger</div>}
                isOpen={ showAddTrigger }
                onClose={ () => {
                    setTriggerData(dialogInitialState)
                    setShowAddTrigger(false) 
                }}
            >
                <DialogBody>
                    <Label>
                        <div>Trigger Name:</div>
                        <HTMLSelect
                            fill={true}
                            onChange={(e) => {
                                setTriggerData({...triggerData, trigger: {...triggerData.trigger, triggerName: e.target.value} })
                            }}
                        >
                            {triggerNames.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </HTMLSelect>
                        <div className='trigger-helper-text'>Enter the desired trigger name</div>
                    </Label>
                    <Label>
                        <div>Short Description:</div>
                        <InputGroup
                            id='trigger-description'
                            value={triggerData.trigger.triggerDescription}
                            onChange={(e) => {
                                setTriggerData({...triggerData, trigger: {...triggerData.trigger, triggerDescription: e.target.value} })
                            }}
                        ></InputGroup>
                        <div className='trigger-helper-text'>Enter a brief description on what the trigger is about</div>
                    </Label>
                </DialogBody>
                <DialogFooter
                    actions={
                        <div>
                            <Button text='Cancel' onClick={ () => {
                                setTriggerData(dialogInitialState) 
                                setShowAddTrigger(false)
                            }}/>
                            <Button text='Add' intent='primary' onClick={ () => handleAddTriggerQuery() }/>
                        </div>
                    }
                >
                </DialogFooter>
            </Dialog>
            <Button intent='success' text='Add Trigger' icon='add-row-bottom'
                onClick={handleAddTrigger}
                loading={isLoad}
                disabled={taskId === '' ? true : false }
            ></Button>
        </div>
    )
}

export default AddTrigger