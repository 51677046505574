import React, { useEffect, useState } from 'react'
import { Switch } from '@blueprintjs/core'
import { useParams } from 'react-router-dom'

const TriggerSwitch = ({ trigger, sheetId, taskId, callback }) => {

    const [prevTrigger, setPrevTrigger] = useState(trigger)
    const params = useParams()

    useEffect(() => {
        setPrevTrigger(trigger)
    }, [trigger])

    const handleToggleTriggerSwitch = () => {
        setPrevTrigger((prevState) => ({ ...prevState, isEnable: !prevState.isEnable}))
    
        fetch('/api/jobs/updateTrigger', { 
            method: 'PUT', 
            body: JSON.stringify({ 
                bookId: params.id, 
                sheetId: sheetId, 
                taskId: taskId, 
                triggerId: prevTrigger.triggerId, 
                triggerData: {
                    triggerName: prevTrigger.triggerName,
                    triggerDescription: prevTrigger.triggerDescription,
                    status: prevTrigger.isEnable ? 'Disabled' : 'Open',
                    rank: prevTrigger.rank,
                    source: prevTrigger.source,
                    sourceRange: prevTrigger.sourceRange,
                    destinationRange: prevTrigger.destinationRange,
                    isEnable: !prevTrigger.isEnable,
                }
            }), 
            headers: { 'Content-Type': 'application/json' } 
        })
            .then(res => res.json())
            .then(data => {
                callback()
            })
    }

    return(
        <Switch
            checked={prevTrigger.isEnable}
            onChange={handleToggleTriggerSwitch}
        ></Switch>
    )
}

export default TriggerSwitch