import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppToaster } from '../../utilities/Toaster/Toaster'
import { AnchorButton, Card, Elevation, FormGroup, InputGroup, Divider, Icon, Spinner, SpinnerSize } from "@blueprintjs/core"

import './Login.css'
import DemandDataToolsLogo from './DemandDataToolsLogo.png'
import MainIconLogo from './MainIconLogo.png'
import Background from './Background.jpg'
import MainBanner from './MainBanner.jpg'

const Login = () => {
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(false)
    const [credentials, setCredentials] = useState({ email: '', password: '' })
    
    const handleLogin = () => {
        setIsLoad(true)
        fetch('/api/auth/login', { method: 'POST', body: JSON.stringify(credentials), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setIsLoad(false)
                localStorage.setItem('jwt', data.token)
                localStorage.setItem('id', data.id)
                localStorage.setItem('temp', credentials.password)
                data.message ? AppToaster.show({ icon: 'info-sign', intent: 'danger', message: data.message }) : navigate('/dashboard')
            })
    }

    return (
        <div className='cards-container'>
            <Card className='card-a' elevation={Elevation.FOUR}>
                <img src={MainIconLogo} alt="Your Image1" className="image" />
            </Card>
            <Card className='card-b'>
                <div className='header-text'/>
                <FormGroup labelFor="agent-id" className='username-form1'> Username
                    <InputGroup large
                        type="text"
                        id="agent-id"
                        leftIcon="user"
                        intent="primary"
                        className='username-input'
                        value={credentials.email}
                        onChange={e => setCredentials({ ...credentials, email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? handleLogin() : '' }
                    />
                </FormGroup>
                <FormGroup labelFor="agent-passcode" className='username-form2'> Password
                    <InputGroup large
                        type="password"
                        id="agent-passcode"
                        leftIcon="key"
                        intent="primary"
                        className='username-input'
                        value={credentials.password}
                        onChange={e => setCredentials({ ...credentials, password: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? handleLogin() : '' }
                    />
                </FormGroup>
                <FormGroup labelFor="agent-login" className=''>
                    <AnchorButton  
                        type="submit"
                        id="agent-login"
                        text="Sign In"
                        icon={isLoad ? <Spinner size={SpinnerSize.SMALL} /> : 'log-in'}
                        disabled={isLoad ? true : false}
                        intent="primary"
                        className='submit-button'
                        onClick={handleLogin}
                    />
                </FormGroup>
            </Card>
        </div>
    )
}

export default Login