import React, { useState } from 'react'
import { Button } from '@blueprintjs/core'
import * as middlewares from '../../../middlewares/triggers'
import { AppToaster } from '../../../utilities/Toaster/Toaster'

import './Triggers.css'

const RunTrigger = ({ triggerIndex, mainData, triggerData, otherData, checkChanges, callback }) => {

    const { checkChangesDescription, checkChangesSourceRange } = checkChanges
    const [ isLoad, setIsLoad ] = useState(false)

    const handleRunTrigger = async () => {
        setIsLoad(true)
        let middlewareName = triggerData.triggerName
        for (const key of Object.keys(middlewares)) {
            // console.log(key)
            // console.log(middlewareName)
            if (middlewareName === key) {
                // console.log(middlewareName)
                let message = await middlewares[key](mainData, triggerData, otherData)
                AppToaster.show({ icon: 'info-sign', intent: 'success', message })
            }
        }
        setIsLoad(false)
        callback()
    }
      

    return(
        <div className=''>
            <Button
                className='run-job-button'
                icon='play'
                small={true}
                intent='success'
                onClick={handleRunTrigger} 
                loading={isLoad}
                disabled={!triggerData.isEnable || ( triggerIndex === otherData.selectedTriggerIndex && (checkChangesDescription || checkChangesSourceRange))}
            />
        </div>
    )
}

export default RunTrigger