import React, { useEffect, useState } from 'react'
import { Alert, Button } from '@blueprintjs/core'
import { AppToaster } from '../../../utilities/Toaster/Toaster'

import './Tasks.css'

const DropTask = ({ bookId, sheetId, taskId, callback }) => {
    const [showDeleteTask, setShowDeleteTask] = useState(false)
    const [currTask, setCurrTask] = useState({})

    useEffect(() => {
        fetch(`/api/jobs/getTasks/${bookId}?sheetId=${sheetId}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                data.data[0].tasks.map(task => {
                    if (task.taskId === taskId) {
                        setCurrTask({ taskId: task.taskId, taskName: task.taskName })
                    }
                })
            })
    }, [taskId])

    const handleDeleteTask = () => {
        fetch('/api/jobs/deleteTask', { method: 'DELETE', body: JSON.stringify({ sheetId, taskId }), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(() => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Task deleted!' })
                callback()
            })
    }

    return(
        <div>
            <Alert
                className='bp4-dark'
                icon='trash'
                intent='danger'
                canEscapeKeyCancel={true}
                canOutsideClickCancel={true}
                isOpen={showDeleteTask}
                onClose={() => setShowDeleteTask(false)}
                cancelButtonText='Cancel'
                onCancel={() => setShowDeleteTask(false)}
                confirmButtonText='Yes'
                onConfirm={() => handleDeleteTask()}
            >
                <p>Are you sure that you want to delete this task?</p>
                <p style={{ fontWeight: 'bold' }}>{currTask.taskName}</p>
            </Alert>
            <Button
                intent='danger'
                text='Drop Task'
                icon='trash'
                onClick={() => setShowDeleteTask(true)}
                disabled={taskId === '' ? true : false }
            ></Button>
        </div>
    )
}

export default DropTask