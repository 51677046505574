import React from 'react'
import decode from 'jwt-decode'
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { FocusStyleManager } from "@blueprintjs/core"

import "normalize.css"
import "@blueprintjs/core/lib/css/blueprint.css"
import "@blueprintjs/icons/lib/css/blueprint-icons.css"
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css"

import Login from './views/Login/Login'
import PageNotFound from './views/PageNotFound/PageNotFound'
import JobsAutomation from './views/JobsAutomation/JobsAutomation'
import Jobs from './views/JobsAutomation/jobs/Jobs'
import ManageWorkspace from './views/JobsAutomation/workspace/ManageWorkspace'
import UserManagement from './views/UserManagement/UserManagement'
import GoogleSpreadsheets from './views/GoogleWorkspace/GoogleSpreadsheets'
import TaskSchedules from './views/TaskSchedules/TaskSchedules'

FocusStyleManager.onlyShowFocusOnTabs();

const App = () => {
	return (
		<Router>
			<div className="App bp4-dark">
				<Routes>
					<Route exact path='/' element={ <Login /> } />
					<Route exact path='/' element={ <PrivateRoute /> }>
						<Route exact path='/task-schedules' element={ <TaskSchedules /> } />
						<Route exact path='/google-spreadsheets' element={ <GoogleSpreadsheets /> } />
						<Route exact path='/dashboard' element={ <JobsAutomation /> } />
						<Route path='/dashboard/:id' element={ <Jobs /> } />
						<Route path='/dashboard/settings/:id' element={ <ManageWorkspace /> } />
						<Route exact path='/user-management' element={ <UserManagement /> } />
					</Route>
					<Route exact path='*' element={ <PageNotFound /> } />
				</Routes>
			</div>
		</Router>
	)
}

const isAuthenticated = () => {
	const token = localStorage.getItem('jwt')
	try {
		decode(token)
		return true
	} catch (error) {
	  	return false
	}
}

const PrivateRoute = () => {
	return isAuthenticated() ? <Outlet /> : <Navigate to='/' />
}

export default App
