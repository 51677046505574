import React, { useState } from 'react'
import { Button, ButtonGroup, ControlGroup, Dialog, DialogBody, DialogFooter, Divider, HTMLSelect, Icon, InputGroup, Label } from '@blueprintjs/core'
import { AppToaster } from '../../../utilities/Toaster/Toaster'

import './Sheets.css'
import { NavLink } from 'react-router-dom'

const AddSheet = ({ bookId, callback }) => {
    const [isLoad, setIsLoad] = useState(false)
    const [showAddSheet, setShowAddSheet] = useState(false)
    const [selectedSheetId, setSelectedSheetId] = useState()
    const [selectedFilteredSheets, setSelectedFilteredSheets] = useState([])

    const getSheetsByBookId = () => {
        fetch(`/api/books/getSheetsByBookId/${bookId}`, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                const sheetCondition = [{ bookId: 0, value: -1, disabled: false, label: '- Please select a sheet -' }]
                data.sheets.forEach(sheet => {
                    const { sheetId, sheetName, sheetStatus } = sheet
                    sheetCondition.push({ bookId: bookId, value: sheetId, label: sheetName, tasks: [], disabled: sheetStatus })

                    setSelectedFilteredSheets(sheetCondition)
                })
            }
        )
    }

    const handleAddSheet = () => {
        setIsLoad(true)
        selectedFilteredSheets.map(sheet => {
            const { bookId, value, label, tasks } = sheet
            if (value === parseInt(selectedSheetId) && label !== '- Please select a sheet -') {
                fetch('/api/jobs/addSheet', { method: 'POST', body: JSON.stringify({ bookId: bookId, sheet: { sheetId: value, sheetName: label, tasks } }), headers: { 'Content-Type': 'application/json' } })
                    .then(res => res.json())
                    .then(data => {
                        AppToaster.show({ icon: 'info-sign', intent: 'success', message: data.message })

                        setShowAddSheet(false)
                        callback(value)
                    })
            }
        })
        
        setIsLoad(false)
    }

    return(
        <div className='wrapper-add-sheet'>
            <Dialog className='bp4-dark' canEscapeKeyCancel={true} canOutsideClickCancel={true}
                icon={<Icon icon='add-to-artifact'></Icon>}
                title={<div>Add Sheet</div>}
                isOpen={showAddSheet}
                onClose={() => setShowAddSheet(false)}
            >
                <DialogBody>
                    <Label>Select a sheet for your workspace:</Label>
                    <ControlGroup>
                        <HTMLSelect
                            fill={true}
                            onChange={event => setSelectedSheetId(event.target.value)}
                        >
                            {selectedFilteredSheets.map((sheet, index) => (
                                <option key={index} value={sheet.value} disabled={sheet.disabled}>{sheet.label}</option>
                            ))}
                        </HTMLSelect>
                    </ControlGroup>
                    <div className='note-div'>
                        <Icon size={20} icon='warning-sign' intent='warning'></Icon>
                        <span> Note: Unselectable sheets are already existing in the workspace.</span>
                    </div>
                </DialogBody>
                <DialogFooter
                    actions={
                        <div>
                            <ButtonGroup>
                                <Button text='Cancel' onClick={() => setShowAddSheet(false)} />
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button
                                    text='Add Sheet'
                                    intent='primary'
                                    disabled={ isLoad ? true : false }
                                    onClick={() => handleAddSheet()}
                                />
                            </ButtonGroup>
                        </div>
                    }
                >
                </DialogFooter>
            </Dialog>
            <Button className='add-sheet-button' icon='add-to-artifact' intent='primary'
                onClick={ () => {
                    getSheetsByBookId()
                    setShowAddSheet(true)
                } }
            >Add Sheet</Button>
            <NavLink to='/dashboard'>
                <Button
                    icon={<Icon icon='power' intent='warning' />}
                    className='task-exit'
                    text='Exit'
                    intent='none'
                    minimal={false}
                    outlined={false}
                />
            </NavLink>
        </div>
    )
}

export default AddSheet