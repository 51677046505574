import React, { useEffect, useState } from 'react'
import { NumericInput } from '@blueprintjs/core'
import { useParams } from 'react-router-dom'

const RankInput = ({ trigger, sheetId, taskId, callback }) => {

    const [prevTrigger, setPrevTrigger] = useState(trigger)
    const params = useParams()

    useEffect(() => {
        setPrevTrigger(trigger)
    }, [trigger])

    const handleEditRank = (value) => {
        setPrevTrigger(prev => ({ ...prev, rank: value }))

        fetch('/api/jobs/updateTrigger', { 
            method: 'PUT', 
            body: JSON.stringify({ 
                bookId: params.id, 
                sheetId: sheetId, 
                taskId: taskId, 
                triggerId: prevTrigger.triggerId, 
                triggerData: {
                    triggerName: prevTrigger.triggerName,
                    triggerDescription: prevTrigger.triggerDescription,
                    status: prevTrigger.status,
                    rank: value,
                    source: prevTrigger.source,
                    sourceRange: prevTrigger.sourceRange,
                    destinationRange: prevTrigger.destinationRange,
                    isEnable: prevTrigger.isEnable,
                }
            }), 
            headers: { 'Content-Type': 'application/json' } 
        })
            .then(res => res.json())
            .then(data => {
                callback()
            })
    }

    return (
        <NumericInput className='rank-input' min={0} fill={true} small={true}
            value={prevTrigger.rank}
            onValueChange={handleEditRank}
            disabled={!prevTrigger.isEnable}
        ></NumericInput>
    )
}

export default RankInput