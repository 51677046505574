import React, { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Button, Menu, MenuItem, Icon, MenuDivider } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import * as middlewares from '../../../../middlewares/triggers'
import { AppToaster } from '../../../../utilities/Toaster/Toaster'

const RunOptions = ({ selectedSheetId, selectedTaskId, callback, sheets, callback2 }) => {
    const [triggers, setTriggers] = useState({})
    const [isLoad, setIsLoad] = useState(false)
    const [sheetName, setSheetName] = useState('')
    const params = useParams()
    const [queryParams] = useSearchParams()

    const handleGetTriggers = () => {
        fetch(`/api/jobs/getTriggers/${params.id}?sheetId=${selectedSheetId}&taskId=${selectedTaskId}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setTriggers(data?.data[0]?.triggers)
                setIsLoad(false)
            })
    }

    const handleGetSheetName = () => {
        const selectedSheet = sheets.find(sheet => sheet.sheetId === parseInt(selectedSheetId))
        setSheetName(selectedSheet.sheetName)
        return selectedSheet.sheetName
    }

    const findLargestRank = (triggers, prop) => {
        return triggers.reduce((largest, current) => {
            return Math.max(largest, current[prop])
        }, -Infinity)
    }

    const handleRunTriggers = async () => {
        setIsLoad(true)
        const openTriggers = triggers.filter(item => item.status === 'Open' && item.rank > 0)
        const largestRank = findLargestRank(openTriggers, 'rank')

        let taskData = {
            bookId: params.id,
            sheetId: selectedSheetId,
            taskId: selectedTaskId,
            taskStatus: 'On-going'
        }

        if (openTriggers.length <= 0) {
            AppToaster.show({ icon: 'info-sign', intent: 'warning', message: 'No open triggers found!' })
            callback2()
        } else {
            fetch('/api/jobs/updateTaskStatus', { method: 'PUT', body: JSON.stringify(taskData), headers: { 'Content-Type': 'application/json' } })
                .then(res => res.json())
                .then(() => {
                    AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Task On-going!' })
                    callback()
                })
        }

        for (let i = 1 ; i <= largestRank ; i++) {
            for (const trigger of openTriggers) {
                if(trigger.rank === i) {
                    const mainData = { 
                        bookId: params.id, 
                        sheetId: selectedSheetId, 
                        taskId: selectedTaskId, 
                        triggerId: trigger.triggerId,
                    }
                    const triggerData = {
                        triggerName: trigger.triggerName,
                        triggerDescription: trigger.triggerDescription,
                        status: trigger.status,
                        rank: trigger.rank,
                        source: trigger.source,
                        sourceRange: trigger.sourceRange,
                        destinationRange: trigger.destinationRange,
                        isEnable: trigger.isEnable,
                    }
                    const otherData = {
                        bookName: queryParams.get("bookName"),
                        sheetName: handleGetSheetName(),
                    }
        
                    let middlewareName = triggerData.triggerName
                    for (const key of Object.keys(middlewares)) {
                        if (middlewareName === key) {
                            let message = await middlewares[key](mainData, triggerData, otherData)
                            AppToaster.show({ icon: 'info-sign', intent: 'success', message })
                            callback2()
                        }
                    }
                }
            }
        }

        taskData.taskStatus = 'Completed'
        fetch('/api/jobs/updateTaskStatus', { method: 'PUT', body: JSON.stringify(taskData), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
        setIsLoad(false)
    }

    const handleResetTriggers = async () => {
        setIsLoad(true)
        const completedTriggers = triggers.filter(item => item.status === 'Completed')
      
        for (const trigger of completedTriggers) {
            try {
                const response = await fetch('/api/jobs/updateTrigger', {
                method: 'PUT',
                body: JSON.stringify({
                    bookId: params.id,
                    sheetId: selectedSheetId,
                    taskId: selectedTaskId,
                    triggerId: trigger.triggerId,
                    triggerData: {
                    triggerName: trigger.triggerName,
                    triggerDescription: trigger.triggerDescription,
                    status: 'Open',
                    rank: trigger.rank,
                    source: trigger.source,
                    sourceRange: trigger.sourceRange,
                    destinationRange: trigger.destinationRange,
                    isEnable: trigger.isEnable,
                    }
                }),
                headers: { 'Content-Type': 'application/json' }
                })
                if (response.ok) {
                    const data = await response.json()
                }
            } catch (error) {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: error })
            }
        }
        setIsLoad(false)
        callback()
    }

    const handleOpenTask = async() => {
        setIsLoad(true)
        const taskData = {
            bookId: params.id,
            sheetId: selectedSheetId,
            taskId: selectedTaskId,
            taskStatus: 'Open'
        }

        await handleResetTriggers()
        await fetch('/api/jobs/updateTaskStatus', { method: 'PUT', body: JSON.stringify(taskData), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(() => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Task Open!' })
                setIsLoad(false)
                callback()
            })
    }

    return (
        <Popover2
            minimal={false}
            interactionKind="click"
            placement="bottom"
            onOpening={handleGetTriggers}
            content={
                <Menu>
                    <MenuItem
                        icon={<Icon icon='play' intent='success' />}
                        text="Run All"
                        onClick={handleRunTriggers}
                        />
                    <MenuItem
                        icon={<Icon icon='fast-forward' intent='warning' />}
                        text="Open Task (beta)"
                        onClick={handleOpenTask}
                    />
                    <MenuDivider />
                    <MenuItem
                        icon={<Icon icon='reset' intent='primary' />}
                        text="Reset All Triggers"
                        onClick={handleResetTriggers}
                    />
                </Menu>
            }
            renderTarget={({ isOpen, ref, ...targetProps }) => (
                <Button
                    {...targetProps}
                    alignText='left'
                    icon='menu-open'
                    rightIcon='caret-down'
                    elementRef={ref}
                    intent="success"
                    text='Run Options'
                    loading={isLoad}
                />
            )}
        />
    )
}

export default RunOptions