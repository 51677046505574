import React, { useState } from 'react'
import { InputGroup, Dialog, DialogBody, DialogFooter, FormGroup, RadioGroup, Button, TextArea, ButtonGroup, Icon } from '@blueprintjs/core'
import { AppToaster } from '../../../../utilities/Toaster/Toaster'

const AddUser = ({ callback, currentUserDetails }) => {

    const defaultPass = 'pass123'
    const roles = [ { value: 'admin', label: 'Administrator' }, { value: 'user', label: 'User' } ]
    const inviteInitialState = { status: false, firstName: '', middleName: '', lastName: '', email: '', role: '' }
    const [ showInvite, setShowInvite ] = useState(inviteInitialState)
    const [ isLoad, setIsLoad ] = useState(false)

    const checkUserDeatils = (userDetails) => {
        let counter = 0
        if (userDetails.firstName!=='') counter++
        if (userDetails.lastName!=='') counter++
        if (userDetails.email!=='') counter++
        if (userDetails.role!=='') counter++

        return counter
    }

    const handleAddUser = (userDetails) => {
        setIsLoad(true)
        const count = checkUserDeatils(userDetails)
        if (count===4) {
            fetch('/api/auth/register', { method: 'POST', body: JSON.stringify({ password: defaultPass, email: showInvite.email, firstname: showInvite.firstName, lastname: showInvite.lastName, role: showInvite.role, isActive: true }), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setShowInvite(inviteInitialState)
                callback()
                setIsLoad(false)
            })
        } else {
            AppToaster.show({ icon: 'info-sign', intent: 'danger', message: 'Missing details!' })
            setIsLoad(false)
        }
    }

    const handleButtonClick = () => {
        if(currentUserDetails[0].role!=='admin') {
            AppToaster.show({ icon: 'info-sign', intent: 'danger', message: 'Needs admin access!' })
        } else {
            setShowInvite({...inviteInitialState, status: true})
        }
    }

    return (<>
        <Dialog className='bp4-dark dialog-container' canEscapeKeyClose={true} canOutsideClickClose={true}
            icon={<Icon style={{color: '#398bff'}} icon='new-person'></Icon>}
            title='Add User'
            isOpen={showInvite.status}
            onClose={ () => setShowInvite(inviteInitialState) }
        >
            <DialogBody className='dialog-body-container'>
                <h4 style={{width: '100%', marginTop: '0'}}>User Information</h4>
                <FormGroup className='form-group-1' labelInfo='(required)'
                >
                    *First Name
                    <InputGroup id='first-name'
                        onChange={ (e) => setShowInvite({...showInvite, firstName: e.target.value}) }
                    />
                    *Last Name
                    <InputGroup id='last-name'
                        onChange={ (e) => setShowInvite({...showInvite, lastName: e.target.value}) }
                    />
                    *Email
                    <InputGroup id='last-name'
                        onChange={ (e) => setShowInvite({...showInvite, email: e.target.value}) }
                    />
                </FormGroup>
                <h4 style={{width: '100%'}}>Permission</h4>
                <RadioGroup className='radio-group-1' inline
                    onChange={ (e) => setShowInvite({...showInvite, role: e.target.value}) }
                    selectedValue={showInvite.role}
                    options={roles}
                >
                </RadioGroup>
            </DialogBody>
            <DialogFooter
                actions={
                    <ButtonGroup>
                        <Button onClick={ () => setShowInvite(inviteInitialState) }>Cancel</Button>
                        <Button intent='primary' onClick={ () => handleAddUser(showInvite) } loading={isLoad}>Send Invite</Button>
                    </ButtonGroup>
                }
            />
        </Dialog>
        <Button intent='primary' icon='new-person' text='Invite People' style={{float:'right',marginRight:'20px',marginTop:'5px'}}
            onClick={handleButtonClick}
        />
    </>)
}

export default AddUser