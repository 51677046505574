import React, { useState, forwardRef } from 'react'
import { FileInput, Card, Elevation, InputGroup, Divider, Button, Tag, Menu, MenuItem, Icon, ButtonGroup, FormGroup, Alert } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { AppToaster } from '../../../utilities/Toaster/Toaster'
import { useNavigate } from 'react-router-dom'
import boyAvatar from '.././boyAvatar.jpg'
import girlAvatar from '.././girlAvatar.jpg'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
// import hashPassword  from '../../../middlewares/passwords'
// import { hashPassword } from '../../../middlewares/passwords'

const MyAccount = ({ currentUserDetails, callback }) => {
    const [isLoad, setIsLoad] = useState(false)
    const [avatarImage, setAvatarImage] = useState(null)
    const [infoIntent, setInfoIntent] = useState({input1:'none', input2:'none', input3:'none', input4:'none', input5:'none', input6:'none'})
    const [tempCurrentUserDetails, setTempCurrentUserDetails] = useState(currentUserDetails[0])
    const [startDate, setStartDate] = useState(new Date(currentUserDetails[0].birthday))
    const [tempPassword, setTempPassword] = useState({input1:'', input2:'', intent1:'none', intent2:'none'})
    const [showPass, setShowPass] = useState({status1: true, status2: true})
    const [userPass, setUserPass] = useState({password: localStorage.getItem('temp'), status: false})
    const [showDeactivateModal, setShowDeactivateModal] =useState(false)
    const [showDeleteModal, setShowDeleteModal] =useState(false)
    const navigate = useNavigate()

    const handleLogout = () => {
        localStorage.clear('jwt')
        localStorage.clear('id')
        localStorage.clear('temp')
        navigate('/')
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Button className="example-custom-input" onClick={onClick} ref={ref} icon='caret-down' minimal/>
    ))

    const handleAvatarUpload = (event) => {
        const file = event.target.files[0]
        setAvatarImage(file)
    }

    const handleButtonClick = () => {
        const fileInput = document.getElementById('avatarInput')
        fileInput.click()
    }

    const handleEdit = (event, prop, intentProp) => {
        setTempCurrentUserDetails(prev => ({ ...prev, [prop]: event.target.value }))
        if(event.target.value !== currentUserDetails[0][prop]) {
            setInfoIntent(prev => ({...prev, [intentProp]: 'warning'}))
        } else {
            setInfoIntent(prev => ({...prev, [intentProp]: 'none'}))
        }
    }

    const handleCancelEdit = (prop, intentProp) => {
        setTempCurrentUserDetails(prev => ({ ...prev, [prop]: currentUserDetails[0][prop] }))
        setInfoIntent(prev => ({...prev, [intentProp]: 'none'}))
    }

    const handleEditGender = (gender) => {
        setTempCurrentUserDetails(prev => ({ ...prev, gender: gender }))
        if(gender !== currentUserDetails[0].gender) {
            setInfoIntent(prev => ({...prev, input4: 'warning'}))
        } else {
            setInfoIntent(prev => ({...prev, input4: 'none'}))
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        const options = { month: 'long', day: 'numeric', year: 'numeric' }
        return date.toLocaleDateString('en-US', options)
    }

    const isValidPhone = (number) => {
        if (!number.startsWith('09')) {return false}
        if (number.length !== 11) {return false}
        if (!/^\d+$/.test(number)) {return false}
        return true
    }

    const handleEditPhone = (event) => {
        setTempCurrentUserDetails(prev => ({ ...prev, phone: event.target.value }))

        if(isValidPhone(event.target.value)) {
            if(event.target.value !== currentUserDetails[0].phone) {
                setInfoIntent(prev => ({...prev, input6: 'warning'}))
            } else {
                setInfoIntent(prev => ({...prev, input6: 'none'}))
            }
        } else {
            setInfoIntent(prev => ({...prev, input6: 'danger'}))
        }
    }

    const isValidEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailPattern.test(email)
    }

    const handleEditEmail = (event) => {
        setTempCurrentUserDetails(prev => ({ ...prev, email: event.target.value }))

        if(isValidEmail(event.target.value)) {
            if(event.target.value !== currentUserDetails[0].email) {
                setInfoIntent(prev => ({...prev, input5: 'warning'}))
            } else {
                setInfoIntent(prev => ({...prev, input5: 'none'}))
            }
        } else {
            setInfoIntent(prev => ({...prev, input5: 'danger'}))
        }
    }

    const handleEditBirthday = (date) => {
        setStartDate(date)
        setTempCurrentUserDetails(prev => ({ ...prev, birthday: date }))

        if(formatDate(new Date(date)) !== formatDate(new Date(currentUserDetails[0].birthday))) {
            setInfoIntent(prev => ({...prev, input3: 'warning'}))
        } else {
            setInfoIntent(prev => ({...prev, input3: 'none'}))
        }
    }

    const handleEditUser = () => {
        setIsLoad(true)
        fetch(`/api/auth/editUser/${tempCurrentUserDetails.id}`, { 
            method: 'PUT', 
            body: JSON.stringify(tempCurrentUserDetails), 
            headers: { 'Content-Type': 'application/json' } 
        })
            .then(res => {res.json()})
            .then( () => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'General Information is updated!' })
                setInfoIntent({input1:'none', input2:'none', input3:'none', input4:'none', input5:'none', input6:'none'})
                setIsLoad(false)
                callback()
            }
        )
    }

    const handleApplyChanges = () => {
        const { firstname, lastname, email, phone } = tempCurrentUserDetails
        const errorFields = []
      
        if (firstname === '') {errorFields.push('First Name')}
        if (lastname === '') {errorFields.push('Last Name')}
        if (!isValidEmail(email)) {errorFields.push('Email')}
        if (!isValidPhone(phone)) {errorFields.push('Phone')}
      
        if (errorFields.length === 0) {
            handleEditUser()
        } else {
            const message = `${errorFields.join(', ')} field is invalid`
            AppToaster.show({ icon: 'info-sign', intent: 'danger', message: message })
        }
    }

    const handleRevertChanges = () => {
        setInfoIntent({input1:'none', input2:'none', input3:'none', input4:'none', input5:'none', input6:'none'})
        setTempCurrentUserDetails(currentUserDetails[0])
        setStartDate(new Date(currentUserDetails[0].birthday))
    }

    const handleEditPass1 = (event) => {
        const input1 = event.target.value
        const input2 = tempPassword.input2
      
        setTempPassword((prev) => ({...prev, input1, intent1: checkPasswords(input1, input2), intent2: checkPasswords(input2, input1)}))
    }
      
    const handleEditPass2 = (event) => {
        const input1 = tempPassword.input1
        const input2 = event.target.value
      
        setTempPassword((prev) => ({...prev, input2, intent1: checkPasswords(input1, input2), intent2: checkPasswords(input2, input1)}))
    }
      
    const checkPasswords = (password1, password2) => {
        if (password1 === '' && password2 === '') return 'none'
        else if (password1 !== password2 || password1 === '') return 'danger'
        else return 'success'
    }

    const handleChangePassword = () => {
        if(tempPassword.input1!==tempPassword.input2) {
            AppToaster.show({ icon: 'info-sign', intent: 'danger', message: 'Inputted Password is Different!' })
            return
        }
        if(!validatePassword(tempPassword.input1)) {
            AppToaster.show({ icon: 'info-sign', intent: 'danger', message: 'New Password should contain at least 7 character, and a number!' })
            return
        }

        // const hashedPassword = hashPassword(tempPassword.input1)
        // console.log(hashedPassword)

        setIsLoad(true)
        fetch(`/api/auth/editUser/${tempCurrentUserDetails.id}`, { 
            method: 'PUT', 
            body: JSON.stringify({ ...currentUserDetails[0], password: currentUserDetails[0].password }),
            headers: { 'Content-Type': 'application/json' } 
        })
            .then(res => {res.json()})
            .then( () => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Password updated!' })
                setTempPassword({input1:'', input2:'', intent1:'none', intent2:'none'})
                setShowPass({status1: true, status2: true})
                setIsLoad(false)
                callback()
            }
        )
    }

    const handleCancelPassword = () => {
        setTempPassword({input1:'', input2:'', intent1:'none', intent2:'none'})
        setShowPass({status1: true, status2: true})
    }

    const validatePassword = (input) => {
        if (input.length < 7) return false
        if (!/[a-zA-Z]/.test(input)) return false
        if (!/\d/.test(input)) return false
        return true
    }

    const handleDeactivateUser = () => {
        setIsLoad(true)
        fetch(`/api/auth/editUser/${currentUserDetails[0].id}`, { 
            method: 'PUT', 
            body: JSON.stringify({ ...currentUserDetails[0], isActive: false }), 
            headers: { 'Content-Type': 'application/json' } 
        })
            .then(res => {res.json()})
            .then( () => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Account Deactivated!' })
                setIsLoad(false)
                setShowDeactivateModal(false)
                callback()
                handleLogout()
            }
        )
    }

    const handleDeleteUser = () => {
        setIsLoad(true)
        fetch(`/api/auth/deleteUser/${currentUserDetails[0].id}`, { method: 'DELETE' })
            .then(res => {res.json()})
            .then( () => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'User Successfully Deleted!' })
                setIsLoad(false)
                callback()
                handleLogout()
            }
        )
    }

    return (
        <div className='acc-cards-container'>
            <Card elevation={Elevation.FOUR} className='card1'>
                
                <p className='account-header'>General Information</p>
                <div className='account-firstline'>
                    <div className='account-firstline-a'>
                        <Tag minimal fill intent={infoIntent.input1}>First Name</Tag>
                        <InputGroup large 
                            value={tempCurrentUserDetails.firstname}  
                            onChange={(event)=>handleEdit(event,'firstname','input1')}
                            intent={infoIntent.input1}
                            leftElement={<Icon icon='new-text-box' intent={infoIntent.input1}/>}
                            rightElement={ infoIntent.input1 === 'none' ? '' :
                                <Button intent='danger' icon='cross-circle' 
                                    onClick={()=>handleCancelEdit('firstname','input1')}
                                />
                            }
                        />
                    </div>
                    <div className='account-firstline-b'>
                        <Tag minimal fill intent={infoIntent.input2}>Last Name</Tag>
                        <InputGroup large 
                            value={tempCurrentUserDetails.lastname} 
                            onChange={(event)=>handleEdit(event,'lastname','input2')}
                            intent={infoIntent.input2}
                            leftElement={<Icon icon='new-text-box'/>} 
                            rightElement={ infoIntent.input2 === 'none' ? '' :
                                <Button intent='danger' icon='cross-circle' 
                                    onClick={()=>handleCancelEdit('lastname','input2')}
                                />
                            }
                        />
                    </div>
                </div>
                <div className='account-firstline'>
                    <div className='account-firstline-a'>
                        <Tag minimal fill intent={infoIntent.input3}>Birthday</Tag>
                        <InputGroup large 
                            value={formatDate(new Date(startDate))}
                            onChange={()=>{}}
                            intent={infoIntent.input3}
                            leftElement={<Icon icon='timeline-events'/>} 
                            rightElement={
                                <DatePicker showYearDropdown showMonthDropdown withPortal
                                    selected={startDate} 
                                    onChange={handleEditBirthday} 
                                    customInput={<ExampleCustomInput />}
                                />
                            }
                        />
                    </div>
                    <div className='account-firstline-b'>
                        <Tag minimal fill intent={infoIntent.input4}>Gender</Tag>
                        <InputGroup large 
                            value={tempCurrentUserDetails.gender}
                            onChange={()=>{}}
                            intent={infoIntent.input4}
                            leftElement={<Icon icon='people'/>}
                            rightElement={
                                <Popover2
                                    content={
                                        <Menu>
                                            <MenuItem text="Male" onClick={()=>handleEditGender('Male')}/>
                                            <MenuItem text="Female" onClick={()=>handleEditGender('Female')}/>
                                            <MenuItem text="Unspecified" onClick={()=>handleEditGender('Unspecified')}/>
                                        </Menu>
                                    }
                                    placement="bottom-end"
                                >
                                    <Button minimal rightIcon="caret-down"/>
                                </Popover2>
                            }
                        />
                    </div>
                </div>
                <div className='account-firstline'>
                    <div className='account-firstline-a'>
                        <Tag minimal fill intent={infoIntent.input5}>Email</Tag>
                        <InputGroup large 
                            value={tempCurrentUserDetails.email} 
                            onChange={(event)=>handleEditEmail(event)}
                            intent={infoIntent.input5}
                            leftElement={<Icon icon='at'/>} 
                            rightElement={ infoIntent.input5 === 'none' ? '' :
                                <Button intent='danger' icon='cross-circle' 
                                    onClick={()=>handleCancelEdit('email','input5')}
                                />
                            }
                        />
                    </div>
                    <div className='account-firstline-b'>
                        <Tag minimal fill intent={infoIntent.input6}>Phone</Tag>
                        <InputGroup large 
                            value={tempCurrentUserDetails.phone} 
                            onChange={(event)=>handleEditPhone(event)}
                            intent={infoIntent.input6}
                            leftElement={<Icon icon='phone'/>} 
                            rightElement={ infoIntent.input6 === 'none' ? '' :
                                <Button intent='danger' icon='cross-circle' 
                                    onClick={()=>handleCancelEdit('phone','input6')}
                                />
                            }
                        />
                    </div>
                </div>
                {(infoIntent.input1 !== 'none' || infoIntent.input2 !== 'none' || infoIntent.input3 !== 'none' || infoIntent.input4 !== 'none' || infoIntent.input5 !== 'none' || infoIntent.input6 !== 'none') &&
                <ButtonGroup style={{float:'right', marginTop:'25px', marginRight:'35%'}} >
                    <Button text='Apply Changes' intent='success' 
                        onClick={handleApplyChanges} 
                        loading={isLoad}
                    />_
                    <Button text='Revert Changes' intent='danger' 
                        onClick={handleRevertChanges}
                    />
                </ButtonGroup>}
                <Divider className='divider'/>
                <div className='footer-container'>
                    <p className='account-footer'>Account Security</p>
                    <div className='account-footer1'>
                        <div className='account-footer1-a'>
                        <Tag minimal fill intent={tempPassword.intent1}>New Password</Tag>
                            <InputGroup disabled
                                placeholder='Type Desired New Password'
                                value={tempPassword.input1}
                                onChange={(event)=>handleEditPass1(event)}
                                intent={tempPassword.intent1}
                                type={showPass.status1 ? 'password' : 'text'}
                                leftElement={
                                    <Button minimal 
                                        icon={showPass.status1 ? 'eye-off' : 'eye-open'}
                                        onClick={() => setShowPass(prevState => ({ ...prevState, status1: !prevState.status1 }))}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className='account-footer1'>
                        <div className='account-footer1-a'>
                        <Tag minimal fill intent={tempPassword.intent2}>Confirm Password</Tag>
                            <InputGroup disabled
                                placeholder='Confirm Password'
                                value={tempPassword.input2}
                                onChange={(event)=>handleEditPass2(event)}
                                intent={tempPassword.intent2}
                                type={showPass.status2 ? 'password' : 'text'}
                                leftElement={
                                    <Button minimal 
                                        icon={showPass.status2 ? 'eye-off' : 'eye-open'}
                                        onClick={() => setShowPass(prevState => ({ ...prevState, status2: !prevState.status2 }))}
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
                {(tempPassword.intent1 !== 'none' || tempPassword.intent2 !== 'none') &&
                <ButtonGroup style={{float:'right', marginTop:'25px', marginRight:'68%'}} >
                    <Button text='Change Password' intent='success' 
                        onClick={handleChangePassword}
                        loading={isLoad}
                    />_
                    <Button text='Cancel' intent='danger' 
                        onClick={handleCancelPassword}
                    />
                </ButtonGroup>}
            </Card>
            <div className='card2'>
                <Card elevation={Elevation.FOUR} className='card2a'>
                        <div className='profile-image-container'>
                            {avatarImage ? (
                                <img src={URL.createObjectURL(avatarImage)} alt='Your Image' className='image1' />
                                ) : (
                                currentUserDetails[0].gender === 'Female' ? 
                                    <img src={girlAvatar} alt='Default Avatar' className='image1' /> :
                                    <img src={boyAvatar} alt='Default Avatar' className='image1' />
                                )
                            }
                        </div>
                        <div className='details-container'>
                            <div className='fullname-container'>{currentUserDetails[0].firstname} {currentUserDetails[0].lastname}</div>
                            <div className='email-container'>{currentUserDetails[0].email}</div>
                            <Tag className='tag-container' round intent={currentUserDetails[0].isActive?'success':'danger'}>{currentUserDetails[0].isActive?'Active':'Inactive'}</Tag>
                        </div>
                        <div className='user-password-container' 
                            onClick={()=>setUserPass(prev=> ({...prev, status: !prev.status}))}
                        >
                            <Icon icon={userPass.status ? 'double-chevron-up' : 'double-chevron-down'}/>
                            {userPass.status ? 'Hide' : 'Show'} Password
                            <Icon icon={userPass.status ? 'double-chevron-up' : 'double-chevron-down'}/>
                        </div>
                        <div className='user-password-container2'>{userPass.status ? userPass.password : '' }</div>
                </Card>
                <Card elevation={Elevation.FOUR} className='card2b'>
                    <div>
                        <FileInput style={{ display: 'none' }}
                            id="avatarInput"
                            accept='image/*'
                            inputProps={{ multiple: false }}
                            buttonText='Upload'
                            text='Choose Profile Image'
                            onInputChange={handleAvatarUpload}
                        />
                        <Button disabled fill intent='primary' onClick={handleButtonClick} text='Change Profile Picture' style={{ marginBottom: '5px' }}/>
                        <Button fill intent='warning' onClick={()=>setShowDeactivateModal(true)} style={{ marginBottom: '5px' }}>Disable Account</Button>
                        <Button fill intent='danger' onClick={()=>setShowDeleteModal(true)} style={{ marginBottom: '5px' }}>Delete Account</Button>
                    </div>
                </Card>
            </div>
            <Alert className='bp4-dark' canEscapeKeyCancel canOutsideClickCancel intent='warning' icon='info-sign'
                isOpen={showDeactivateModal}
                loading={isLoad}
                onCancel={()=>setShowDeactivateModal(false)}
                cancelButtonText='Cancel'
                onConfirm={handleDeactivateUser}
            >
                <p>Are you sure you want to deactivate your account?</p>
            </Alert>
            <Alert className='bp4-dark' canEscapeKeyCancel canOutsideClickCancel intent='danger' icon='info-sign'
                isOpen={showDeleteModal}
                loading={isLoad}
                onCancel={()=>setShowDeleteModal(false)}
                cancelButtonText='Cancel'
                onConfirm={handleDeleteUser}
            >
                <p>Are you sure you want to delete your account?</p>
            </Alert>
        </div>
    )
}

export default MyAccount