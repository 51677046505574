import React, { useState } from 'react'
import { Button, ButtonGroup, Dialog, DialogBody, DialogFooter, Icon, InputGroup, Label } from '@blueprintjs/core'
import { AppToaster } from '../../../utilities/Toaster/Toaster'

import './Tasks.css'

const NewTask = ({ bookId, sheetId, callback }) => {
    const [isLoad, setIsLoad] = useState(false)
    const [showAddTask, setShowAddTask] = useState(false)
    const [task, setTask] = useState(
        {
            taskId: `TK-${Date.now()}`,
            taskName: '',
            taskStatus: 'New',
            taskProgress: 0,
            triggers: [],
            schedule: {
                mode: 'Manual',
                recurrDay: '',
                recurrPattern: '',
                startTime: ''
            }
        }
    )

    const handleAddTask = () => {
        setIsLoad(true)
        fetch('/api/jobs/addTask', { method: 'POST', body: JSON.stringify({ bookId: bookId, sheetId: sheetId, task }), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(() => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Task Added!' })
                
                setTask({
                    taskId: `TK-${Date.now()}`,
                    taskName: '',
                    taskStatus: 'New',
                    taskProgress: 0,
                    triggers: [],
                    schedule: {
                        mode: 'Manual',
                        recurrDay: '',
                        recurrPattern: '',
                        startTime: ''
                    }
                })
                setShowAddTask(false)
                setIsLoad(false)
                callback()
            }
        )
    }
    
    return(
        <div>
            <Dialog
                className='bp4-dark'
                canEscapeKeyCancel={true}
                canOutsideClickCancel={true}
                icon={<Icon icon='new-layer' />}
                title={<div className='dialog-header-label'>Create New Task</div>}
                isOpen={showAddTask}
                onClose={() => setShowAddTask(false)}
            >
                <DialogBody>
                    <Label>
                        <div>Task Name:</div>
                        <InputGroup
                            value={task.taskName}
                            onChange={event => setTask({ ...task, taskName: event.target.value})}
                        />
                        <div className='task-helper-text'>Enter the desired task name</div>
                    </Label>
                </DialogBody>
                <DialogFooter
                    actions={
                        <div>
                            <ButtonGroup>
                                <Button text='Cancel' onClick={() => setShowAddTask(false)} />
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button
                                    text='Add'
                                    intent='primary'
                                    loading={isLoad}
                                    onClick={() => handleAddTask()}
                                />
                            </ButtonGroup>
                        </div>
                    }
                >
                </DialogFooter>
            </Dialog>
            <Button intent='primary' text='New Task' icon='new-layer'
                onClick={() => setShowAddTask(true)}
            ></Button>
        </div>
    )
}

export default NewTask