import React from 'react'

import Layout from '../../components/Layout/Layout'
import TerminalWorkers from './components/TerminalWorkers'
import TaskScheduling from './components/TaskScheduling'

import './TaskSchedules.css'

const TaskSchedules = () => {
    return (
        <Layout>
            <TerminalWorkers />
            {/* <TaskScheduling /> */}
        </Layout>
    )
}

export default TaskSchedules