import React, { useState } from 'react'
import { Menu, MenuItem, Icon } from '@blueprintjs/core'
import { NavLink } from 'react-router-dom'

import './Sidebar.css'

const Sidebar = () => {
    const [isActive, setIsActive] = useState('dashboard')
    
    return (
        <Menu className='menu'>
            <NavLink to='/dashboard' className={({ isActive }) => (isActive ? setIsActive('dashboard') : 'inactive')}>
            <MenuItem className={ isActive==='dashboard' ? 'menu-item-dashboard-active' : 'menu-item-dashboard' } icon={<Icon icon='grid-view' intent='none' style={{ color: '#FFFFFF' }} />} text="Dashboard" active={isActive === 'dashboard'} />
            </NavLink>

            <h5 className='bp4-menu-header'>INTEGRATION SERVICES</h5>
            <NavLink to='/google-spreadsheets' className={({ isActive }) => (isActive ? setIsActive('google-spreadsheets') : 'inactive')}>
                <MenuItem className='sidebar-menu-item' icon={<Icon icon='panel-table' intent='none' style={{ color: '#27AE60' }} />} text="Google Spreadsheets" active={isActive === 'google-spreadsheets'} />
            </NavLink>
            <NavLink to='/seller-central-api' className={({ isActive }) => (isActive ? setIsActive('seller-central-api') : 'inactive')}>
                <MenuItem disabled={true} className='sidebar-menu-item' icon={<Icon icon='shopping-cart' intent='none' style={{ color: '#F39C12' }} />} text="Seller Central API" active={isActive === 'seller-central-api'} />
            </NavLink>

            <h5 className='bp4-menu-header'>OPERATIONS</h5>
            <NavLink to='/task-schedules' className={({ isActive }) => (isActive ? setIsActive('task-schedules') : 'inactive')}>
                <MenuItem className='sidebar-menu-item' icon={<Icon icon='calendar' intent='none' style={{ color: '#2980B9' }} />} text="Task Schedules" active={isActive === 'task-schedules'} />
            </NavLink>
            
            <h5 className='bp4-menu-header'>ADMINISTRATION</h5>
            <NavLink to='/user-management' className={({ isActive }) => (isActive ? setIsActive('user-management') : 'inactive')}>
                <MenuItem className='sidebar-menu-item' icon={<Icon icon='mugshot' intent='none' style={{ color: '#2980B9' }} />} text="User Management" active={isActive === 'user-management'} />
            </NavLink>
        </Menu>
    )
}

export default Sidebar