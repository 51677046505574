import React, { useEffect, useState } from 'react'
import { InputGroup, Button, ButtonGroup, Icon } from '@blueprintjs/core'
import { useParams } from 'react-router-dom'

const DescriptionInput = ({ trigger, sheetId, taskId, callback, checkChangesDescription, setCheckChangesDescription, selectedTrigger, setSelectedTrigger, selectedTriggerIndex, setSelectedTriggerIndex }) => {

    const [prevTrigger, setPrevTrigger] = useState(trigger)
    const params = useParams()
    const [isLoad, setIsLoad] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        setPrevTrigger(trigger)
    }, [trigger])

    const checkValueChanges = (temp) => {
        if(trigger.triggerDescription !== temp) {
            setCheckChangesDescription(true)
            setSelectedTrigger(trigger)
        } else {
            setCheckChangesDescription(false)
        }
    }

    const handleEditDescription = (event) => {
        setIsEditing(true)
        setSelectedTriggerIndex(selectedTriggerIndex)
        setPrevTrigger(prev => ({ ...prev, triggerDescription: event.target.value }))
        checkValueChanges(event.target.value)
    }

    const handleSubmitEdit = () => {
        setIsLoad(true)
        fetch('/api/jobs/updateTrigger', { 
            method: 'PUT', 
            body: JSON.stringify({ 
                bookId: params.id, 
                sheetId: sheetId, 
                taskId: taskId, 
                triggerId: prevTrigger.triggerId, 
                triggerData: {
                    triggerName: prevTrigger.triggerName,
                    triggerDescription: prevTrigger.triggerDescription,
                    status: prevTrigger.status,
                    rank: prevTrigger.rank,
                    source: prevTrigger.source,
                    sourceRange: prevTrigger.sourceRange,
                    destinationRange: prevTrigger.destinationRange,
                    isEnable: prevTrigger.isEnable,
                }
            }), 
            headers: { 'Content-Type': 'application/json' } 
        })
            .then(res => res.json())
            .then(data => {
                callback()
                setIsLoad(false)
                setCheckChangesDescription(false)
                setIsEditing(false)
            })
    }

    const handleCancelEdit = () => {
        setTimeout(() => {
            setPrevTrigger(prev => ({ ...prev, triggerDescription: trigger.triggerDescription }))
            setCheckChangesDescription(false)
        }, 200)
        setIsEditing(false)
        setIsLoad(false)
    }

    return (
        <InputGroup
            style={{ fontSize: '13px', border: '1px solid #1B1B32' }}
            fill={true}
            small={false}
            value={prevTrigger.triggerDescription}
            onChange={handleEditDescription}
            disabled={!prevTrigger.isEnable}
            onBlur={isEditing ? handleCancelEdit : null}
            leftElement={<Icon icon='manually-entered-data'/>}
            onKeyPress={e => e.key === 'Enter' ? handleSubmitEdit() : '' }
            rightElement={
                checkChangesDescription && selectedTrigger.triggerId === prevTrigger.triggerId ?
                <ButtonGroup>
                    <Button icon='small-tick' intent='success'
                        onClick={handleSubmitEdit}
                        loading={isLoad}
                    ></Button>
                    <Button icon='cross-circle' intent='danger'
                        onClick={handleCancelEdit}
                    ></Button>
                </ButtonGroup> : null
            }
        />
    )
}

export default DescriptionInput