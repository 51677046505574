import React, { useState } from 'react'
import { Button, Dialog, DialogBody, Icon, DialogFooter, ButtonGroup, InputGroup, Label } from '@blueprintjs/core'
import { AppToaster } from '../../../../utilities/Toaster/Toaster'

const CreateWorker = ({ callback }) => {
    const [isLoad, setIsLoad] = useState(false)
    const [showCreateWorker, setShowCreateWorker] = useState(false)
    const [newWorker, setNewWorker] = useState({ workerId: '', workerName: '', workerStatus: 'Vacant' })

    const handleCreateWorker = () => {
        setIsLoad(true)
        newWorker.workerId = 'WR-'+ Date.now()
        fetch('/api/workers/createNewWorker', { method: 'POST', body: JSON.stringify(newWorker), headers: { 'Content-Type': 'application/json' } })
            .then(() => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Worker created!' })
                setShowCreateWorker(false)
                setIsLoad(false)
                callback()
            })
    }

    return (
        <div className="create-worker-wrapper">
            <Dialog
                style={{ width: '20%' }}
                className='bp4-dark'
                canEscapeKeyCancel={true}
                canOutsideClickCancel={true}
                icon={<Icon className='icon-color' icon='plus' intent='danger'></Icon>}
                title='Create New Worker'
                isOpen={showCreateWorker}
                onClose={() => setShowCreateWorker(false)}
            >
                <DialogBody>
                    <Label>
                        New Worker Name:
                        <InputGroup
                            type='text'
                            leftIcon='build'
                            fill={true}
                            onChange={event => setNewWorker({ ...newWorker, workerName: event.target.value })}
                        />
                    </Label>
                </DialogBody>
                <DialogFooter
                    actions={
                        <div>
                            <ButtonGroup>
                                <Button text='Cancel' onClick={() => setShowCreateWorker(false)} />
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button
                                    text='Create'
                                    intent='primary'
                                    disabled={ isLoad ? true : false }
                                    onClick={() => handleCreateWorker()}
                                />
                            </ButtonGroup>
                        </div>
                    }
                >
                </DialogFooter>
            </Dialog>
            <Button
                fill={false}
                intent='primary'
                text='Create Worker'
                icon={<Icon icon='plus' intent='primary' />}
                onClick={() => setShowCreateWorker(true)}
            ></Button>
        </div>
    )
}

export default CreateWorker