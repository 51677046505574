const CompleteStatus = async (mainData, triggerData) => {
    const { bookId, sheetId, taskId, triggerId } = mainData
    triggerData.status = 'Completed'
  
    try {
        const response = await fetch('/api/jobs/updateTrigger', { method: 'PUT', body: JSON.stringify({ bookId: bookId, sheetId: sheetId, taskId: taskId, triggerId: triggerId, triggerData: triggerData }), headers: { 'Content-Type': 'application/json' } })
        const data = await response.json()
    } catch (error) {
        console.error(error)
    }
}

const getCellsInRange = (range) => {
    range = range.replace(/\s/g, '')
  
    if (range.includes(':')) {
        var parts = range.split(':')
    
        var startColumn = parts[0].match(/[A-Z]+/)[0]
        var endColumn = parts[1].match(/[A-Z]+/)[0]
        var startRow = parseInt(parts[0].match(/\d+/)[0])
        var endRow = parseInt(parts[1].match(/\d+/)[0])

        var cells = []
    
        for (var row = startRow; row <= endRow; row++) {
            for (var col = getColumnIndex(startColumn); col <= getColumnIndex(endColumn); col++) {
                var cell = getColumnLetter(col) + row
                cells.push(cell)
            }
        }
  
        return cells

    } else {
        var validCellRef = /^[A-Z]+\d+$/
        if (validCellRef.test(range)) {
            return [range]
        }
    }
    return []
}
  
const getColumnIndex = (columnLetter) => {
    var index = 0
    for (var i = 0; i < columnLetter.length; i++) {
        index = index * 26 + columnLetter.charCodeAt(i) - 64
    }
    return index
}
  
const getColumnLetter = (columnIndex) => {
    var letter = ''
    while (columnIndex > 0) {
        var remainder = (columnIndex - 1) % 26
        letter = String.fromCharCode(65 + remainder) + letter
        columnIndex = Math.floor((columnIndex - 1) / 26)
    }
    return letter
}

const getColumnLength = (cellRange) => {
    if (!cellRange.includes(':')) { return 1 }

    const columnStart = cellRange.match(/([A-Z]+)/)[0]
    const columnEnd = cellRange.match(/:([A-Z]+)/)[1]

    const startCharCode = getColumnCharCode(columnStart)
    const endCharCode = getColumnCharCode(columnEnd)

    return endCharCode - startCharCode + 1
}

const getColumnCharCode = (columnName) => {
    let charCode = 0
    const len = columnName.length

    for (let i = 0; i < len; i++) {
        charCode *= 26
        charCode += columnName.charCodeAt(i) - 64
    }
    return charCode
}

const checkBraces = (str) => {
    const extractedStrings = []
    let incrementedString = str
  
    const modifiedString = str.replace(/\[([^[\]]+)]/g, (match, capturedString) => {
        extractedStrings.push(capturedString)
        return capturedString
    })
  
    if (extractedStrings.length > 0) {
        incrementedString = str.replace(/\[([^[\]]+)]/g, (match, capturedString) => {
            const nextCell = incrementSpreadsheetCell(capturedString)
            return nextCell
        })
    }
  
    return {
        modifiedString: modifiedString.replace(/[[\]]/g, ''),
        incrementedString,
    }
}
  
const incrementSpreadsheetCell = (cellString) => {
    const isNumeric = /^\d+$/
    const incrementChar = (char) => {
        if (char === 'Z') {
            return 'A'
        } else {
            return String.fromCharCode(char.charCodeAt(0) + 1)
        }
    }
  
    let incrementedCell = ''
    let overflow = true
    let i = cellString.length - 1
  
    if (isNumeric.test(cellString)) {
        return `[${Number(cellString) + 1}]`
    }
  
    while (overflow && i >= 0) {
        const currentChar = cellString[i]
        const nextChar = incrementChar(currentChar)
    
        if (nextChar === 'A') {
            incrementedCell = nextChar + incrementedCell
            overflow = true
        } else {
            incrementedCell = nextChar + incrementedCell
            overflow = false
        }
        i--
    }
  
    if (overflow) {
        incrementedCell = 'A' + incrementedCell
    } else {
        incrementedCell = cellString.slice(0, i + 1) + incrementedCell
    }
  
    return `[${incrementedCell}]`
}

export const CopyPaste = async (mainData, triggerData, otherData) => {
    const { bookId } = mainData
    const { sourceRange, destinationRange, source } = triggerData
    const { main, sub, tag } = source
    const { sheetName } = otherData
  
    try {
        const response = await fetch(`/api/google-services/batchGetData/${bookId}?sheetName=${sub.sheetName}&range=${sourceRange}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
        const data = await response.json()

        const updateResponse = await fetch('/api/google-services/batchUpdateData', { method: 'POST', body: JSON.stringify({ bookId: bookId, sheetName: sheetName, range: destinationRange, values: data.values }), headers: { 'Content-Type': 'application/json' } })
        const updateData = await updateResponse.json()
        CompleteStatus(mainData, triggerData)
        return 'Data Copied and Pasted!'
    } catch (error) {
        throw error
    }
}

export const Clear = async (mainData, triggerData, otherData) => {
    const { bookId } = mainData
    const { sourceRange } = triggerData
    const { sheetName } = otherData
  
    try {
        const response = await fetch('/api/google-services/batchClearData', { method: 'POST', body: JSON.stringify({ bookId: bookId, sheetName: sheetName, range: sourceRange }), headers: { 'Content-Type': 'application/json' } })
        const data = await response.json()
        await CompleteStatus(mainData, triggerData)
        return 'Data Cleared!'
    } catch (error) {
        throw error
    }
}
  
export const InsertText = async (mainData, triggerData, otherData) => {
    const { bookId } = mainData
    const { sourceRange, destinationRange, source } = triggerData
    const { sheetName } = otherData
    const value = [[sourceRange]]
    const cellsArr = getCellsInRange(destinationRange)

    try {
        for (const cell of cellsArr) {
            try {
                const updateResponse = await fetch('/api/google-services/batchUpdateData', { method: 'POST', body: JSON.stringify({ bookId: bookId, sheetName: sheetName, range: cell, values: value }), headers: { 'Content-Type': 'application/json' } })
                const updateData = await updateResponse.json()
            } catch (error) {
                throw error
            }
        }
        CompleteStatus(mainData, triggerData)
        return 'Data Pasted!'
    } catch (error) {
        throw error
    }
}

export const InsertDate1Day = async (mainData, triggerData, otherData) => {
    const { bookId } = mainData
    const { sourceRange, destinationRange } = triggerData
    const { sheetName } = otherData

    const cellsArr = getCellsInRange(destinationRange)
    const x = destinationRange.length
    var data = {}
  
    try {
        const response = await fetch(`/api/google-services/batchGetData/${bookId}?sheetName=${sheetName}&range=${sourceRange}`, { method: 'GET' })
        data = await response.json()
    } catch (error) {
        throw error
    }

    const result = []

    for (const [index, cell] of cellsArr.entries()) {
        const dateString = data.values[0][0]
        const parts = dateString.split('/')
        const date = new Date(parts[2], parts[0] - 1, parts[1])
        date.setDate(date.getDate() + index + 1)
        const newDateString = (date.getMonth() + 1 ) + '/' + date.getDate() + '/' + date.getFullYear()
        result.push(newDateString)
    }

    try {
        const updateResponse = await fetch('/api/google-services/batchUpdateData', { method: 'POST', body: JSON.stringify({ bookId: bookId, sheetName: sheetName, range: destinationRange, values: [result] }), headers: { 'Content-Type': 'application/json' } })
        const updateData = await updateResponse.json()

        CompleteStatus(mainData, triggerData)
        return 'Data Copied and Pasted!'
        
    } catch (error) {
        throw error
    }
}

export const InsertDate1Month = async (mainData, triggerData, otherData) => {
    const { bookId } = mainData
    const { sourceRange, destinationRange } = triggerData
    const { sheetName } = otherData

    const cellsArr = getCellsInRange(destinationRange)
    const x = destinationRange.length
    var data = {}
  
    try {
        const response = await fetch(`/api/google-services/batchGetData/${bookId}?sheetName=${sheetName}&range=${sourceRange}`, { method: 'GET' })
        data = await response.json()
    } catch (error) {
        throw error
    }

    const result = []

    for (const [index, cell] of cellsArr.entries()) {
        const dateString = data.values[0][0]
        const [monthName, year] = dateString.split(' ')
        const monthLookup = {
            'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
            'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
        }
        const monthIndex = monthLookup[monthName]
        const date = new Date(year, monthIndex, 1)
        const newDate = new Date(date.getFullYear(), date.getMonth() + index + 1, 1)
    
        if (newDate.getMonth() !== (date.getMonth() + index + 1) % 12) {
            newDate.setDate(0)
        }
    
        const newDateString = (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' + newDate.getFullYear()
        result.push(newDateString)
    }

    try {
        const updateResponse = await fetch('/api/google-services/batchUpdateData', { method: 'POST', body: JSON.stringify({ bookId: bookId, sheetName: sheetName, range: destinationRange, values: [result] }), headers: { 'Content-Type': 'application/json' } })
        const updateData = await updateResponse.json()

        CompleteStatus(mainData, triggerData)
        return 'Data Copied and Pasted!'
    } catch (error) {
        throw error
    }
}

export const InsertFormula = async (mainData, triggerData, otherData) => {
    const { bookId, sheetId, taskId, triggerId } = mainData
    const { sourceRange, destinationRange, source, isEnable, rank, status, triggerDescription, triggerName } = triggerData
    const { sheetName } = otherData
    const { modifiedString: sourceModified, incrementedString: sourceIncremented } = checkBraces(sourceRange)
    const { modifiedString: destinationModified, incrementedString: destinationIncremented } = checkBraces(destinationRange)

    const cellsArr = getCellsInRange(destinationModified)
    const columnCount = getColumnLength(destinationModified)
    const result = []
    let subArray = []
  
    for ( let i = 0 ; i < cellsArr.length ; i++ ) {
        subArray.push(sourceModified)
        
        if (subArray.length === columnCount) {
            result.push(subArray)
            subArray = []
        }
    } 

    try {
        const updateResponse = await fetch('/api/google-services/batchUpdateData', { method: 'POST', body: JSON.stringify({ bookId: bookId, sheetName: sheetName, range: destinationModified, values: result }), headers: { 'Content-Type': 'application/json' } })
        const updateData = await updateResponse.json()
    } catch (error) {
        throw error
    } finally {
        try {
            const response = await fetch(`/api/google-services/batchGetData/${bookId}?sheetName=${sheetName}&range=${destinationModified}`, { method: 'GET' })
            const data = await response.json()
      
            const updateResponse = await fetch('/api/google-services/batchUpdateData', { method: 'POST', body: JSON.stringify({ bookId: bookId, sheetName: sheetName, range: destinationModified, values: data.values }), headers: { 'Content-Type': 'application/json' } })
            const updateData = await updateResponse.json()

            try {
                const response = await fetch('/api/jobs/updateTrigger', { 
                    method: 'PUT', 
                    body: JSON.stringify({ 
                        bookId: bookId, 
                        sheetId: sheetId, 
                        taskId: taskId, 
                        triggerId: triggerId, 
                        triggerData: {
                            triggerName: triggerName,
                            triggerDescription: triggerDescription,
                            status: 'Completed',
                            rank: rank,
                            source: source,
                            sourceRange: sourceIncremented,
                            destinationRange: destinationIncremented,
                            isEnable: isEnable,
                        }
                    }), 
                    headers: { 'Content-Type': 'application/json' } 
                })
                const data = await response.json()
                return 'Data Pasted!'
            } catch (error) {
                throw error
            }
        } catch (error) {
            throw error
        }
    }
}

export const Search = async (mainData, triggerData, otherData) => {
    const { bookId } = mainData
    const { sourceRange, source } = triggerData
    const { sub } = source

    try {
        const response = await fetch(`/api/google-services/batchGetData/${bookId}?sheetName=${sub.sheetName}&range=${sourceRange}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
        const data = await response.json()
        
        const searchWord = "Altr"
        let found = false

        for (const row of data.values) {
            if (row.includes(searchWord)) {
              found = true;
              break;
            }
        }

        if (found) {
            console.log('found it!')
        } else {
            console.log('not found in the sheet!')
        }
      
    } catch (error) {
        console.log(error)
    }
}