import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Menu, MenuItem, Icon, MenuDivider } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

const SwitchOptions = ({ selectedSheetId, selectedTaskId, callback }) => {
    const [triggers, setTriggers] = useState({})
    const [isLoad, setIsLoad] = useState(false)
    const params = useParams()

    const handleGetTriggers = () => {
        fetch(`/api/jobs/getTriggers/${params.id}?sheetId=${selectedSheetId}&taskId=${selectedTaskId}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setTriggers(data?.data[0]?.triggers)
                setIsLoad(false)
            }
        )
    }

    const handleToggleEnable = async (toggleValue) => {
        setIsLoad(true)
        const statusToggle = toggleValue ? 'Open' : 'Disabled'

        for (const trigger of triggers) {
            try {
                setTriggers((prev) => ({ ...prev, isEnable: toggleValue}))
                await fetch('/api/jobs/updateTrigger', {
                method: 'PUT',
                body: JSON.stringify({
                    bookId: params.id,
                    sheetId: selectedSheetId,
                    taskId: selectedTaskId,
                    triggerId: trigger.triggerId,
                    triggerData: {
                    triggerName: trigger.triggerName,
                    triggerDescription: trigger.triggerDescription,
                    status: statusToggle,
                    rank: trigger.rank,
                    source: trigger.source,
                    sourceRange: trigger.sourceRange,
                    destinationRange: trigger.destinationRange,
                    isEnable: toggleValue,
                    }
                }),
                headers: { 'Content-Type': 'application/json' }
                })
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoad(false)
        callback()
    }

    const handleResetPriorities = async () => {
        setIsLoad(true)
        for (const trigger of triggers) {
            try {
                await fetch('/api/jobs/updateTrigger', {
                method: 'PUT',
                body: JSON.stringify({
                    bookId: params.id,
                    sheetId: selectedSheetId,
                    taskId: selectedTaskId,
                    triggerId: trigger.triggerId,
                    triggerData: {
                    triggerName: trigger.triggerName,
                    triggerDescription: trigger.triggerDescription,
                    status: trigger.status,
                    rank: 0,
                    source: trigger.source,
                    sourceRange: trigger.sourceRange,
                    destinationRange: trigger.destinationRange,
                    isEnable: trigger.isEnable,
                    }
                }),
                headers: { 'Content-Type': 'application/json' }
                })
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoad(false)
        callback()
    }

    return (
        <Popover2
            minimal={false}
            interactionKind="click"
            placement="bottom"
            onOpening={handleGetTriggers}
            content={
                <Menu>
                    <MenuItem icon={<Icon icon='record' intent='success' />} text="Turn On All"
                        onClick={() => handleToggleEnable(true)}
                    ></MenuItem>
                    <MenuItem icon={<Icon icon='stop' intent='danger' />} text="Turn Off All"
                        onClick={() => handleToggleEnable(false)}
                    ></MenuItem>
                    <MenuDivider />
                    <MenuItem icon={<Icon icon='reset' intent='primary' />} text="Reset All Priority"
                        onClick={handleResetPriorities}
                    ></MenuItem>
                </Menu>
            }
            renderTarget={({ isOpen, ref, ...targetProps }) => (
                <Button
                    {...targetProps}
                    alignText='left'
                    icon='settings'
                    rightIcon='caret-down'
                    elementRef={ref}
                    intent="primary"
                    text='Switch Options'
                    loading={isLoad}
                />
            )}
        />
    )
}

export default SwitchOptions