import React, { useState } from 'react'
import { Alert, Button } from '@blueprintjs/core'
import { AppToaster } from '../../../../utilities/Toaster/Toaster'

const DeactivateUser = ({ user, callback, currentUserDetails }) => {

    const [ showModal, setShowModal ] = useState({status: false, id:''})
    const [ isLoad, setIsLoad ] = useState(false)

    const handleDeactivateUser = (id) => {
        setIsLoad(true)
        fetch(`/api/auth/editUser/${id}`, { 
            method: 'PUT', 
            body: JSON.stringify({ 
                firstname: user.firstname, 
                lastname: user.lastname, 
                email: user.email, 
                role: user.role, 
                isActive: !user.isActive,
                birthday: user.birthday,
                gender: user.gender,
                phone: user.phone,
                picture: user.picture,
            }), 
            headers: { 'Content-Type': 'application/json' } 
        })
            .then(res => {res.json()})
            .then( () => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'User Deactivated!' })
                setShowModal({status: false, id:''})
                setIsLoad(false)
                callback()
            }
        )
    }

    const handleButtonClick = () => {
        if(currentUserDetails[0].role!=='admin') {
            AppToaster.show({ icon: 'info-sign', intent: 'danger', message: 'Needs admin access!' })
        } else {
            setShowModal({...showModal, status: true, id: user.id})
        }
    }

    return (<>
        <Alert canEscapeKeyCancel canOutsideClickCancel intent='danger' className='bp4-dark' icon='disable'
            isOpen={showModal.status}
            cancelButtonText='Cancel'
            onCancel={ () => setShowModal({...showModal, status: false, id: ''}) }
            confirmButtonText='Yes'
            onConfirm={ () => handleDeactivateUser(showModal.id) } 
            loading={isLoad}
        >
            <p>Are you sure that you want to {user.isActive ? 'Deactivate' : 'Reactivate'} User: </p>
            <p>{user.email} ?</p>
        </Alert>
        <Button minimal={true} text={user.isActive ? 'Deactivate' : 'Reactivate'} intent='primary'
            onClick={handleButtonClick}
        />
    </>)
    
}

export default DeactivateUser