import React, { useState } from 'react'
import { Button, Menu, MenuItem, MenuDivider, Icon } from "@blueprintjs/core"
import { Popover2 } from "@blueprintjs/popover2"
import { AppToaster } from '../../../../utilities/Toaster/Toaster'

const SourceSelect = ({ bookId, sheetId, taskId, trigger, callback }) => {
    const [listOfBooks, setListOfBooks] = useState([])

    const handleGetAllBooks = () => {
        fetch('/api/books/getAllBooks', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                let arrBooks = []
                data.result.map(book => {
                    const { bookID, bookName, bookSheets, statusCode } = book
                    if (statusCode === 200) {
                        arrBooks.push({ bookId: bookID, bookName: bookName, sheets: bookSheets })
                    }
                })

                setListOfBooks(arrBooks)
            })
    }

    const handleAddSource = (tag, main, sub) => {
        let triggerData = trigger
        triggerData.source = { tag, main, sub }
        fetch('/api/jobs/updateTrigger', { method: 'PUT', body: JSON.stringify({ bookId, sheetId, taskId, triggerId: triggerData.triggerId, triggerData }), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(() => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Source added!' })
                callback()
            })
    }

    const handleSelectCurrentSheet = () => {
        listOfBooks.map(book => {
            const { bookId, bookName } = book
            if (book.bookId) {
                book.sheets.map(sheet => {
                    const { sheetID, sheetName } = sheet
                    if (sheetId === sheet.sheetID) {
                        handleAddSource('GS', { bookId, bookName }, { sheetID, sheetName })
                    }
                })
            }
        })
    }

    return (
        <div>
            <Popover2
                interactionKind="click"
                placement="bottom"
                content={
                    <Menu>
                        <span style={{ fontWeight: 'bold', fontSize: '13px', marginLeft: '5px' }}>Available Sources</span>
                        <MenuDivider />
                        <MenuItem key={1} disabled={true} icon={<Icon icon='shopping-cart' intent='warning' />} text="Seller Central"></MenuItem>
                        <MenuItem
                            key={2}
                            icon={<Icon icon='panel-table' intent='success' />}
                            text="Google Spreadsheet"
                            onMouseEnter={() => handleGetAllBooks()}
                        >
                            <MenuItem
                                key={3}
                                icon={<Icon icon='document' intent='primary' />}
                                text="Select Current Sheet"
                                onClick={() => handleSelectCurrentSheet()}
                            ></MenuItem>
                            <MenuDivider />
                            {listOfBooks.map(book => {
                                const { bookId, bookName, sheets } = book
                                return <MenuItem
                                    key={bookId}
                                    icon={<Icon intent='success' icon='th-list' />}
                                    text={bookName}
                                >
                                    {sheets.map(sheet => {
                                        const { sheetID, sheetName } = sheet
                                        return <MenuItem
                                            key={sheetID}
                                            icon={<Icon intent='success' icon='document' />}
                                            text={sheetName}
                                            onClick={() => handleAddSource('GS', { bookId, bookName }, { sheetID, sheetName })}
                                        />
                                    })}
                                </MenuItem>
                            })}
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem
                            disabled={trigger.triggerName !== 'InsertText' && trigger.triggerName !== 'InsertFormula'}
                            key={4}
                            icon={<Icon icon='function' intent='primary' />}
                            text="Formula / Text"
                            onClick={() => handleAddSource('FM', 'Formula / Text', '')}
                        ></MenuItem>
                    </Menu>
                }
                renderTarget={({ isOpen, ref, ...targetProps }) => (
                    <Button
                        {...targetProps}
                        disabled={trigger.isEnable !== true ? true : false }
                        small={true}
                        alignText='left'
                        icon='add'
                        rightIcon='caret-down'
                        fill={false}
                        elementRef={ref}
                        intent="warning"
                        text='Add Source'
                    />
                )}
            />
        </div>
    )
}

export default SourceSelect