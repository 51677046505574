import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import { Button, ButtonGroup, Divider } from '@blueprintjs/core'
import './UserManagement.css'
import CurrentUsers from './components/CurrentUsers'
import MyAccount from './components/MyAccount'
import AddUser from './components/sub/AddUser'

const UserManagement = () => {
    const [filterTab, setFilterTab] = useState('users')
    const [isLoad, setIsLoad] = useState(false)
    const [currentUserDetails, setCurrentUserDetails] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(false)

    useEffect(() => {
        setIsLoad(true)
        fetch('/api/auth/getAllUsers', { method: 'GET' })
            .then((response) => response.json())
            .then((data) => {
                const temp = []
                const currentID = localStorage.getItem('id')
                const currentUserDetailsTemp = []

                data.result.forEach((user) => {
                    const { email, firstname, id, isActive, lastname, role, _id, birthday, gender, phone, picture, password } = user
                    temp.push({ email, firstname, id, isActive, lastname, role, _id, birthday, gender, phone, picture, password })

                    if (currentID === id) currentUserDetailsTemp.push({ email, firstname, id, isActive, lastname, role, _id, birthday, gender, phone, picture, password })
                })
                setAllUsers(temp)
                setCurrentUserDetails(currentUserDetailsTemp)
                setIsLoad(false)
        })
    }, [shouldUpdate])

    const callback = () => setShouldUpdate(!shouldUpdate)

    return (
        <Layout>
            <div className='div-header'>
                <ButtonGroup className='button-group-header' minimal={true} large={true}>
                    <Button text='My Account' icon='id-number' 
                        disabled={ filterTab === 'general' ? true : false }
                        intent={ filterTab === 'general' ? 'primary' : '' }
                        onClick={ () => setFilterTab('general') }
                    /><Divider/>
                    <Button text='Users' icon='person'
                        disabled={ filterTab === 'users' ? true : false }
                        intent={ filterTab === 'users' ? 'primary' : '' }
                        onClick={ () => setFilterTab('users') }
                    /><Divider/>
                    <Button text='Others' disabled
                        // disabled={ filterTab === 'more' ? true : false }
                        intent={ filterTab === 'more' ? 'primary' : '' }
                        onClick={ () => setFilterTab('more') }
                    />
                </ButtonGroup>
                {filterTab === 'users' && <AddUser callback={callback} currentUserDetails={currentUserDetails}/>}
            </div>
            {filterTab === 'users' && (<>
                <CurrentUsers allUsers={allUsers} callback={callback} currentUserDetails={currentUserDetails}/>
            </>)}
            {filterTab === 'general' && (<div className='general-container'>
                <MyAccount currentUserDetails={currentUserDetails} callback={callback}/>
            </div>)}
        </Layout>
    )
}

export default UserManagement
