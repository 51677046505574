import React, { useEffect, useState } from 'react'
import { Button } from '@blueprintjs/core'
import {  useParams } from 'react-router-dom'

const ResetTrigger = ({ trigger, sheetId, taskId, callback }) => {

    const [prevTrigger, setPrevTrigger] = useState(trigger)
    const [isLoad, setIsLoad] = useState(false)
    const params = useParams()

    useEffect(() => {
        setPrevTrigger(trigger)
    }, [trigger])

    const handleOpenStatus = () => {
        setIsLoad(true)
        fetch('/api/jobs/updateTrigger', { 
            method: 'PUT', 
            body: JSON.stringify({ 
                bookId: params.id, 
                sheetId: sheetId, 
                taskId: taskId, 
                triggerId: prevTrigger.triggerId, 
                triggerData: {
                    triggerName: prevTrigger.triggerName,
                    triggerDescription: prevTrigger.triggerDescription,
                    status: 'Open',
                    rank: prevTrigger.rank,
                    source: prevTrigger.source,
                    sourceRange: prevTrigger.sourceRange,
                    destinationRange: prevTrigger.destinationRange,
                    isEnable: prevTrigger.isEnable,
                }
            }), 
            headers: { 'Content-Type': 'application/json' } 
        })
            .then(res => res.json())
            .then(data => {
                callback()
                setIsLoad(false)
            })
    }

    return (
        <div>
            <Button small={true} intent='primary' icon='reset'
                onClick={handleOpenStatus}
                loading={isLoad}
            />
        </div>
    )
}

export default ResetTrigger