import React from "react"
import { AnchorButton, Icon, NonIdealState } from '@blueprintjs/core'

import './PageNotFound.css'

const PageNotFound = () => {
    return (
        <div className='wrapper'>
            <NonIdealState>
                <Icon icon="backlink" size={60} className='icon' />
                <h1 className="title">Page Not Found</h1>
                <p className="description">Uh oh, we can't seem to find the page you're looking for. Try going back to the previous page or contact the IT Department for more information.</p>
                <AnchorButton large text="Return to Dashboard" icon="circle-arrow-left" intent="primary" href="/dashboard" />
            </NonIdealState>
        </div>
    )
}

export default PageNotFound