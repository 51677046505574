import React, { useEffect, useState } from 'react'
import { Card, Elevation, Icon, ButtonGroup, Button, Dialog, DialogBody, FormGroup, DialogFooter, InputGroup, Spinner, SpinnerSize, HTMLTable, Alert, Drawer } from '@blueprintjs/core'
import { AppToaster } from '../../utilities/Toaster/Toaster'

import Layout from '../../components/Layout/Layout'

import './GoogleSpreadsheets.css'

const GoogleSpreadsheets = () => {

    const headerArray = ['#', 'Workbook Name', 'Workbook Sheets', 'Status', 'Actions']
    const headerArray2 = ['#', 'Sheet Name', 'Sheet ID']

    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [bookList, setBookList] = useState([])
    const [newBook, setNewBook] = useState('')
    const [newBookName, setNewBookName ] = useState('')
    const [isLoad, setIsLoad] = useState(false)
    const [showAddBook, setShowAddBook] = useState({ status: false, bookID: '' })
    const [showTabs, setShowTabs] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState({ status: false, bookName: '', _id: '' })
    const [showSyncModal, setShowSyncModal] = useState({ status: false, bookID: '', bookName: '' })
    const [showEditModal, setShowEditModal] = useState({ status: false, bookName: '', _id: '', bookID: '' })
    const [tempEdit, setTempEdit] = useState({temp1: '', temp2: ''})
    const [selectedBook, setSelectedBook] = useState({ selectedBookID: 0, selectedBookName: '', selectedBookSheets: [] })

    useEffect(() => {
        fetch('/api/books/getAllBooks', { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                setBookList(data.result) 
            }
        )
    }, [shouldUpdate])

    const handleAdd = () => {
        setIsLoad(true)
        fetch('/api/books/newBook', { method: 'POST', body: JSON.stringify({ bookID: newBook, bookName: newBookName }), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                if (data.message === 'Book already exist!' ) {
                    AppToaster.show({ icon: 'info-sign', intent: 'danger', message: data.message })
                } else if (data.message.includes('books validation failed:')) {
                    AppToaster.show({ icon: 'info-sign', intent: 'danger', message: 'Book Name & Book ID is required!' })
                } else {
                    AppToaster.show({ icon: 'info-sign', intent: 'success', message: data.message })
                    setShowAddBook({ status: false, bookID: '' })
                }
                setIsLoad(false)
                setShouldUpdate(!shouldUpdate)
                setNewBook('')
                setNewBookName('')
            }
        )
    }

    const handleDelete = (_id) => {
        setIsLoad(true)
        fetch(`/api/books/deleteBook/${_id}`, { method: 'DELETE' })
            .then(res => res.json())
            .then( () => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Book Successfully Deleted!' })
                setShouldUpdate(!shouldUpdate)
                setShowDeleteModal({ status: false, bookName: '', _id: '' })
                setIsLoad(false)
            }
        )
    }

    const handleSync = (bookID) => {
        setIsLoad(true)
        fetch(`/api/google-services/syncBook/${bookID}`, { method: 'GET'})
            .then(response => response.json())
            .then(data => {
                if (data.statusCode ===  200) {
                    setShowSyncModal({ status: false, bookID: '', bookName: '' })
                    AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Sync Completed!' })
                } else {
                    AppToaster.show({ icon: 'info-sign', intent: 'danger', message: data.statusText })
                }
                setShowSyncModal({ status: false, bookID: '', bookName: '' })
                setShouldUpdate(!shouldUpdate)
                setIsLoad(false)
            }
        )
    }

    const handleEdit = (_id) => {
        setIsLoad(true)
        fetch(`/api/books/editBook/${_id}`, { method: 'PUT', body: JSON.stringify({ bookID: newBook, bookName: newBookName }), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                if (data.message === 'Book updated!') {
                    AppToaster.show({ icon: 'info-sign', intent: 'success', message: data.message })
                    setShowEditModal({ status: false, bookName: '', _id: '', bookID: '' })
                    setNewBook('')
                    setNewBookName('')
                    setTempEdit({temp1: '', temp2: ''})
                } else {
                    AppToaster.show({ icon: 'info-sign', intent: 'danger', message: data.message })
                }
                setShouldUpdate(!shouldUpdate)
                setIsLoad(false)
            }
        )
    }

    const handleShowTabs = (bookID, bookName, bookSheets) => {
        if (showTabs === true) {
            setShowTabs(false)
            setSelectedBook({ selectedBookID: 0, selectedBookName: '', selectedBookSheets: [] })
            return
        }
        setShowTabs(true)
        setSelectedBook({ selectedBookID: bookID, selectedBookName: bookName, selectedBookSheets: bookSheets })
    }

    const handleCopy = async (bookNameOrBookID) => {
        await navigator.clipboard.writeText(bookNameOrBookID);
        AppToaster.show({ icon: 'clipboard', intent: 'success', message: 'Book name copied to clipboard!' })
    }

    const openBookURL = (bookID, sheetID) => {
        if (sheetID === undefined) window.open(`https://docs.google.com/spreadsheets/d/${bookID}`, '_blank')
        else window.open(`https://docs.google.com/spreadsheets/d/${bookID}/edit#gid=${sheetID}`, '_blank')
    }

    return (
        <Layout>
            <Card elevation={Elevation.FOUR} className='gss-card'>
                <h4>
                    <Dialog className='bp4-dark' icon='new-layers' canEscapeKeyCancel={true} canOutsideClickCancel={true}
                        title='Add Google Spreadsheet Source'
                        isOpen={showAddBook.status}
                        onClose={ () => setShowAddBook( { status: false, bookID:'' }) }
                    >
                        <DialogBody>
                            <FormGroup 
                                helperText='Please enter the book id of the spreadsheet where you can locate at the url bar of your browser'
                                label={<label htmlFor='book-id'>Workbook ID </label>}
                                labelFor='book-id'
                                labelInfo='(required)'    
                            >
                                <InputGroup id='book-id'
                                    onChange={ (e) => setNewBook(e.target.value) }
                                />
                            </FormGroup>
                            <FormGroup
                                helperText='Please enter your desired book name'
                                label={<label htmlFor='book-name'>Workbook Name </label>}
                                labelFor='book-name'
                                labelInfo='(required)'
                            >
                                <InputGroup id='book-name'
                                    onChange={ (e) => setNewBookName(e.target.value) }
                                />
                            </FormGroup>
                        </DialogBody>
                        <DialogFooter
                            actions={
                                <div>
                                    <Button
                                        onClick={ () => setShowAddBook({ status: false, bookID:'' }) }
                                    >Cancel</Button>
                                    <Button intent='primary'
                                        disabled={ isLoad ? true : false }
                                        onClick={ () => handleAdd() }
                                    >Add Spreadsheet</Button>
                                </div>
                            }
                        ></DialogFooter>
                    </Dialog>
                    <ButtonGroup className='btn-tools'>
                        <Button style={{ fontSize: '13px' }} icon='new-layers' intent='primary' outlined={false}
                            onClick={ () => setShowAddBook({ ...showAddBook, status: true }) }
                        >Register Spreadsheet</Button>
                    </ButtonGroup>
                </h4>
                <HTMLTable className='html-table' bordered={true} striped={true} interactive={true} compact={true}> 
                    <thead><tr>{ headerArray.map((header, index) => <th className='html-table-header' style={{ textAlign: 'center' }} key={index}>{header}</th>) }</tr></thead>
                    <tbody>
                        {bookList.map((book, index) => (
                        <tr key={index}>
                            <td><div className='book-counter'>{index+1}</div></td>
                            <td onClick={ () => handleCopy(book.bookName) }>
                                <span className='drawer-book-name'><Icon className='book-name-icon' icon='th' intent='success'></Icon> {book.bookName}</span>
                            </td>
                            <td>
                                <Button className='book-tabs' icon='document-open' minimal={true} intent='primary'
                                    disabled={book.statusCode === 200 ? false : true }
                                    onClick={ () => handleShowTabs(book.bookID, book.bookName, book.bookSheets) }
                                >View Sheets</Button>
                            </td>
                            <td><div className='book-status-td'>
                                <p className='status-statustext'>{book.statusText === '' ? 'Unsynced' : book.statusText === 'OK' ? <span className='status-true'>{book.statusText}</span> : <span className='status-false'>{book.statusText}</span>}</p>
                            </div></td>
                            <td className='btn-actions' style={{ textAlign: 'center' }}>
                                <ButtonGroup className='action-td' minimal={false}>
                                    <Button className='button1' icon='refresh' intent='primary' text='Sync'
                                        onClick={ () => setShowSyncModal({ status: true, bookID: book.bookID, bookName: book.bookName }) }
                                    />
                                </ButtonGroup>
                                <ButtonGroup className='action-td' minimal={false}>
                                    <Button className='button2' icon='edit' intent='warning' text='Modify'
                                        onClick={ () => setShowEditModal({ status: true, bookName: book.bookName, _id: book._id, bookID: book.bookID }) }
                                    />
                                </ButtonGroup>
                                <ButtonGroup className='action-td' minimal={false}>
                                    <Button className='button3' icon='trash' intent='danger' text='Remove'
                                        onClick={ () => setShowDeleteModal({ status: true, bookName: book.bookName, _id: book._id}) }
                                    />
                                </ButtonGroup>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </HTMLTable>
                <Alert className='bp4-dark' canEscapeKeyCancel={true} canOutsideClickCancel={true} intent='danger' icon='trash'
                    isOpen={showDeleteModal.status}
                    cancelButtonText='Cancel'
                    onCancel={ () => setShowDeleteModal({ status: false, bookName: '', _id: '' }) }
                    confirmButtonText='Yes'
                    loading={isLoad}
                    onConfirm={ () => handleDelete(showDeleteModal._id) }
                >
                    <p>Are you sure that you want to delete book: </p>
                    <p className='delete-modal-bookname'>{showDeleteModal.bookName} ?</p>
                </Alert>
                <Alert className='bp4-dark' canEscapeKeyCancel={true} canOutsideClickCancel={true} intent='primary' icon='automatic-updates'
                    isOpen={showSyncModal.status}
                    cancelButtonText='Cancel'
                    onCancel={ () => setShowSyncModal({ status:false, bookID:'', bookName: '' }) }
                    confirmButtonText='Yes'
                    loading={isLoad}
                    onConfirm={ () => handleSync(showSyncModal.bookID) }
                > 
                    <p>Are you sure you want to Sync book: </p>
                    <p className='sync-modal-bookname'>{showSyncModal.bookName}</p>
                </Alert>
                <Dialog className='bp4-dark'
                    icon='annotation'
                    title='Edit book'
                    isOpen={showEditModal.status}
                    onClose={ () => {
                        setShowEditModal(({ status: false, bookName: '', _id: '', bookID: '' })) 
                        setTempEdit({temp1: '', temp2: ''})
                    }}
                    canEscapeKeyCancel={true}
                    canOutsideClickCancel={true} 
                >
                    <DialogBody>
                        <FormGroup 
                            helperText={<div className='edit-retain-helper'>Click to retain original value</div>}
                            label={<label htmlFor='book-id' className='bold-label'> Book ID </label>}
                            labelFor='book-id'
                            labelInfo='(required)'
                        >
                            <InputGroup
                                id='book-id'
                                value={tempEdit.temp1}
                                onChange={(e) => {
                                    setTempEdit({ ...tempEdit, temp1: e.target.value })
                                    setNewBook(e.target.value)
                                }}
                            />
                            <Button className='edit-retain-button' minimal={true} icon='clipboard' small={true}
                                onClick={ () => setTempEdit({...tempEdit, temp1: showEditModal.bookID}) }
                            ></Button>
                        </FormGroup>
                        <FormGroup 
                            helperText={<div className='edit-retain-helper'>Click to retain original value</div>}
                            label={<label htmlFor='book-name' className='bold-label'> Book Name </label>}
                            labelFor='book-name'
                            labelInfo='(required)'
                        >
                            <InputGroup
                                id='book-name'
                                value={tempEdit.temp2}
                                onChange={(e) => {
                                    setTempEdit({ ...tempEdit, temp2: e.target.value })
                                    setNewBookName(e.target.value)
                                }}
                            />
                            <Button className='edit-retain-button' minimal={true} icon='clipboard' small={true}
                                onClick={ () => setTempEdit({...tempEdit, temp2: showEditModal.bookName}) }
                            ></Button>
                        </FormGroup>
                    </DialogBody>
                    <DialogFooter
                        actions={
                            <div>
                                <ButtonGroup>
                                    <Button
                                        onClick={ () => setShowEditModal({ status: false, bookName: '', _id: '', bookID: '' }) }
                                    >Cancel</Button>
                                </ButtonGroup>
                                <ButtonGroup>
                                    <Button intent='primary'
                                        disabled={ isLoad ? true : false }
                                        onClick={ () => handleEdit(showEditModal._id) }
                                    >Update Book</Button>
                                </ButtonGroup>
                            </div>
                        }
                    ></DialogFooter>
                </Dialog>
                <Drawer className='bp4-dark' icon='th' canEscapeKeyClose={true} canOutsideClickClose={true} isCloseButtonShown={false} size='35%' style={{backgroundColor: '#0B1B30', borderLeft: '1px solid #9d9d9d'}}
                    title={<h4 className='drawer-bookname'>{selectedBook.selectedBookName}<div className='drawer-bookname-button'><Button intent='primary' minimal={true} icon='share' large={false}  onClick={ () => openBookURL(selectedBook.selectedBookID)}></Button></div></h4>}
                    isOpen={showTabs}
                    onClose={ () => setShowTabs(!showTabs) }
                >
                    <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
                        <div style={{ width: '100%', overflowX: 'auto' }}>
                            <HTMLTable className='htmltable' bordered={true} striped={true} interactive={true} compact={true}>
                                <thead>
                                    <tr>{headerArray2.map((header, index) => (<th className='drawer-list-table-header' key={index} style={{ textAlign: 'center' }}>{header}</th>))}</tr>
                                </thead>
                                <tbody>{selectedBook.selectedBookSheets.map((sheet, index) => (
                                    <tr className='drawer-tr' key={index}>
                                        <td><div className='drawer-table-counter'>{index + 1}</div></td>
                                        <td><div className='drawer-sheetname'>{sheet.sheetName}<Button className='drawer-sheetname-button' icon='share' small={true} minimal={true} intent='none' onClick={ () => openBookURL(selectedBook.selectedBookID,sheet.sheetID) }></Button></div></td>
                                        <td><div className='drawer-sheetid'>{sheet.sheetID}</div></td>
                                    </tr>))}
                                </tbody>
                            </HTMLTable>
                        </div>
                    </div>
                </Drawer>
            </Card>
        </Layout>
    )
}

export default GoogleSpreadsheets