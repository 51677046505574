import React, { useState } from 'react'
import { Alert, Button } from '@blueprintjs/core'
import { AppToaster } from '../../../../utilities/Toaster/Toaster'

const DeleteUser = ({ user, callback, currentUserDetails }) => {

    const [ showDeleteModal, setShowDeleteModal ] = useState({status: false, id:''})
    const [ isLoad, setIsLoad ] = useState(false)

    const handleDeleteUser = (id) => {
        setIsLoad(true)
        fetch(`/api/auth/deleteUser/${id}`, { method: 'DELETE' })
            .then(res => {res.json()})
            .then( () => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'User Successfully Deleted!' })
                setShowDeleteModal({status: false, id:''})
                setIsLoad(false)
                callback()
            }
        )
    }

    const handleButtonClick = () => {
        if(currentUserDetails[0].role!=='admin') {
            AppToaster.show({ icon: 'info-sign', intent: 'danger', message: 'Needs admin access!' })
        } else {
            setShowDeleteModal({...showDeleteModal, status: true, id: user.id})
        }
    }

    return (<>
        <Alert canEscapeKeyCancel canOutsideClickCancel intent='danger' className='bp4-dark' icon='trash'
            isOpen={showDeleteModal.status}
            cancelButtonText='Cancel'
            onCancel={ () => setShowDeleteModal({...showDeleteModal, status: false, id: ''}) }
            confirmButtonText='Yes'
            onConfirm={ () => handleDeleteUser(showDeleteModal.id) } 
            loading={isLoad}
        >
            <p>Are you sure that you want to delete User: </p>
            <p>{user.email} ?</p>
        </Alert>
        <Button minimal={true} text='Delete' intent='danger'
            onClick={handleButtonClick}
        />
    </>)
    
}

export default DeleteUser