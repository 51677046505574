import React, { Component } from 'react'

import './Layout.css'

import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'

class Layout extends Component {
    render() {
        return (
            <div>
                <Header />
                <section>
                    <nav><Sidebar /></nav>
                    <article>{this.props.children}</article>
                </section>
                <footer>Copyright © { new Date().getFullYear() }. Powered by <b>Channel Precision Inc.</b></footer>
            </div>
        )
    }
}

export default Layout