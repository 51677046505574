import React, { useState, useEffect } from 'react'
import { InputGroup, Button, Icon, ButtonGroup } from '@blueprintjs/core'
import { useParams } from 'react-router-dom'
import { AppToaster } from '../../../../utilities/Toaster/Toaster'

const SourceRangeInput = ({ trigger, sheetId, taskId, callback, checkChangesSourceRange, setCheckChangesSourceRange, selectedTrigger, setSelectedTrigger, selectedTriggerIndex, setSelectedTriggerIndex }) => {

    const [prevTrigger, setPrevTrigger] = useState(trigger)
    const params = useParams()
    const [isLoad, setIsLoad] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        setPrevTrigger(trigger)
    }, [trigger])

    const checkValueChanges = (temp) => {
        if(trigger.sourceRange !== temp) {
            setCheckChangesSourceRange(true)
            setSelectedTrigger(trigger)
        } else {
            setCheckChangesSourceRange(false)
        }
    }

    const handleEditSourceRange = (event) => {
        setIsEditing(true)
        setSelectedTriggerIndex(selectedTriggerIndex)
        setPrevTrigger(prev => ({ ...prev, sourceRange: event.target.value }))
        checkValueChanges(event.target.value)
    }

    const isValidExcelCellRange = (cellRange) => {
        // console.log(`Trigger Source Tag: ${trigger.source.tag}`)
        // if (trigger.source.tag !== 'FM') {
        //     var pattern = /^([A-Za-z]{1,3}\d+)|([A-Za-z]{1,3}\d+:[A-Za-z]{1,3}\d+)|([A-Za-z]{1,3}:[A-Za-z]{1,3})$/
        //     console.log(`Cell Range: ${cellRange}`)
        //     console.log(`Pattern Test (Cell Range): ${pattern.test(cellRange)}`)
        //     return pattern.test(cellRange)
        // } else {
        //     return true
        // }

        return true
    }

    const handleSubmitEdit = () => {
        setIsLoad(true)
        if(isValidExcelCellRange(prevTrigger.sourceRange)) {
            fetch('/api/jobs/updateTrigger', { 
                method: 'PUT', 
                body: JSON.stringify({ 
                    bookId: params.id, 
                    sheetId: sheetId, 
                    taskId: taskId, 
                    triggerId: prevTrigger.triggerId, 
                    triggerData: {
                        triggerName: prevTrigger.triggerName,
                        triggerDescription: prevTrigger.triggerDescription,
                        status: prevTrigger.status,
                        rank: prevTrigger.rank,
                        source: prevTrigger.source,
                        sourceRange: prevTrigger.sourceRange,
                        destinationRange: prevTrigger.destinationRange,
                        isEnable: prevTrigger.isEnable,
                    }
                }), 
                headers: { 'Content-Type': 'application/json' } 
            })
                .then(res => res.json())
                .then(data => {
                    callback()
                    setIsLoad(false)
                    setCheckChangesSourceRange(false)
                    setIsEditing(false)
                })
        } else {
            setIsLoad(false)
            setIsEditing(false)
            AppToaster.show({ icon: 'info-sign', intent: 'danger', message: 'Invalid Cell Range!' })
        }
    }

    const handleCancelEdit = () => {
        setTimeout(() => {
            setPrevTrigger(prev => ({ ...prev, sourceRange: trigger.sourceRange }))
            setCheckChangesSourceRange(false)
        }, 200)
        setIsEditing(false)
    }

    return (
        <InputGroup
            intent='none'
            style={{ fontSize: '13px', borderLeft: '3px solid #2D72D2' }}
            fill={true}
            small={false}
            value={prevTrigger.sourceRange}
            onChange={handleEditSourceRange}
            disabled={!prevTrigger.isEnable || (prevTrigger.triggerName === 'Paste' && prevTrigger.source.tag !== 'FM')}
            onBlur={isEditing ? handleCancelEdit : null}
            leftElement={prevTrigger.source.tag === 'FM' ? <Icon icon='function'/> : <Icon icon='list-detail-view'/>}
            onKeyPress={e => e.key === 'Enter' ? handleSubmitEdit() : '' }
            rightElement={
                checkChangesSourceRange && selectedTrigger.triggerId === prevTrigger.triggerId ?
                <ButtonGroup>
                    <Button icon='small-tick' intent='success'
                        onClick={handleSubmitEdit}
                        loading={isLoad}
                    ></Button>
                    <Button icon='cross-circle' intent='danger'
                        onClick={handleCancelEdit}
                    ></Button>
                </ButtonGroup> : null
            }
        ></InputGroup>
    )
}

export default SourceRangeInput