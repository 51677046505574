import React, { useEffect, useState } from 'react'
import { Card, Elevation, Button, Spinner } from '@blueprintjs/core'
import { NavLink } from 'react-router-dom'

import Layout from '../../components/Layout/Layout'
import CreateWorkspace from './workspace/CreateWorkspace'

import './JobsAutomation.css'

const JobsAutomation = () => {
    const [isLoad, setIsLoad] = useState(false)
    const [books, setBooks] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(false)

    useEffect(() => {
        setIsLoad(true)
        fetch('/api/jobs/getAllWorkspaces', { method: 'GET', headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setBooks(data.collections)
                setIsLoad(false)
            })
    }, [shouldUpdate])

    return (
        <Layout>
            <div className="container">
                <CreateWorkspace callback={() => setShouldUpdate(!shouldUpdate)} />
            </div>
            {isLoad
                ?   <div>
                        <div className='jobs-automation-spinner-cover'></div>
                        <Spinner intent='warning' className='jobs-automation-spinner' />
                    </div>
                :   ''}
            {books.map((book, bookIndex) => (
                <Card elevation={Elevation.ONE} interactive={true} key={bookIndex} className='card-collection'>
                    <h3>
                        {book.bookName}
                        <NavLink to={`/dashboard/settings/${book.bookId}?bookName=${book.bookName}`}>
                            <Button icon='cog' className='manage-collection' />
                        </NavLink>
                        <NavLink to={`/dashboard/${book.bookId}?bookName=${book.bookName}`}>
                            <Button fill={false} intent='warning' icon='open-application' className='btn-open-collection'>Automate</Button>
                        </NavLink>
                    </h3>
                </Card>
            ))}
        </Layout>
    )
}

export default JobsAutomation