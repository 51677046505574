import React, { useState } from 'react'
import { Button, ButtonGroup, ControlGroup, Dialog, DialogBody, DialogFooter, HTMLSelect, Icon, InputGroup, Label } from '@blueprintjs/core'
import { AppToaster } from '../../../utilities/Toaster/Toaster'

import './Workspace.css'

const CreateWorkspace = ({ callback }) => {
    const [isLoad, setIsLoad] = useState(false)
    const [filteredBookOptions, setFilteredBookOptions] = useState([])
    const [showCreateWorkspace, setShowCreateWorkspace] = useState(false)
    const [selectedWorkbookId, setSelectedWorkbookId] = useState(0)

    const getAvailBooks = () => {
        fetch('/api/books/getAllBooks', { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                let bookCondition =[{ value: 0, disabled: false, label: '- Please select a workbook -'} ]

                data.result.forEach(book => {
                    const { bookName, bookID, statusCode, jobWorkspaceStatus, bookSheets } = book
                    bookCondition.push({ value: bookID, label: bookName, sheets: [], disabled: !jobWorkspaceStatus && statusCode === 200 ? false : true})
                })
                
                setFilteredBookOptions(bookCondition)
            }
        )
    }

    const handleCreateWorkspace = () => {
        setIsLoad(true)
        filteredBookOptions.map(book => {
            const { label, value, sheets } = book
            if (value === selectedWorkbookId && value !== 0) {
                fetch('/api/jobs/createWorkspace', { method: 'POST', body: JSON.stringify({ bookId: value, bookName: label, sheets }), headers: { 'Content-Type': 'application/json' } })
                    .then(res => res.json())
                    .then(data => {
                        AppToaster.show({ icon: 'info-sign', intent: 'success', message: data.message })

                        setShowCreateWorkspace(false)
                        callback()
                    })
            }
        })
        
        setIsLoad(false)
    }

    return(
        <div className='header-container'>
            <Dialog className='bp4-dark' canEscapeKeyCancel={true} canOutsideClickCancel={true}
                icon={<Icon className='icon-color' icon='applications' intent='danger' size='20px'></Icon>}
                title={<div className='dialog-header-label'>Create New Workspace</div>}
                isOpen={showCreateWorkspace}
                onClose={() => setShowCreateWorkspace(false)}
            >
                <DialogBody>
                    <Label>Select a workbook for your workspace:</Label>
                    <ControlGroup>
                        <HTMLSelect
                            fill={true}
                            onChange={event => setSelectedWorkbookId(event.target.value)}
                        >
                            {filteredBookOptions.map((book, index) => (
                                <option key={index} value={book.value} disabled={book.disabled}>{book.label}</option>
                            ))}
                        </HTMLSelect>
                    </ControlGroup>
                    <div className='note-div'>
                        <Icon icon='warning-sign' intent='warning'></Icon>
                        <span> Note: Unselectable workbooks are either already existing as a workspace or still unsynced in the <a href='/google-spreadsheets'>Google Spreadsheets</a> table.</span>
                    </div>
                </DialogBody>
                <DialogFooter
                    actions={
                        <div>
                            <ButtonGroup>
                                <Button text='Cancel' onClick={() => setShowCreateWorkspace(false)} />
                            </ButtonGroup>
                            <ButtonGroup>
                                <Button
                                    text='Create'
                                    intent='primary'
                                    disabled={ isLoad ? true : false }
                                    onClick={() => handleCreateWorkspace()}
                                />
                            </ButtonGroup>
                        </div>
                    }
                >
                </DialogFooter>
            </Dialog>
            <Button
                className='header-button'
                intent='primary'
                text='Create Workspace'
                icon='applications'
                onClick={() => {
                    getAvailBooks()
                    setShowCreateWorkspace(true)
                }}
            ></Button>
        </div>
    )
}

export default CreateWorkspace