import React, { useEffect, useState } from 'react'
import { Alert, Button } from '@blueprintjs/core'

import './Triggers.css'
import { AppToaster } from '../../../utilities/Toaster/Toaster'

const DeleteTrigger = ({ triggerIndex, triggerData, callback, otherData, checkChanges }) => {

    const { name, bookId, sheetId, taskId, triggerId } = triggerData
    const { checkChangesDescription, checkChangesSourceRange } = checkChanges
    const initialState = { bookId: '', sheetId: '', taskId: '', triggerId: '' }

    const [ isLoad, setIsLoad ] = useState(false)
    const [ showDeleteJob, setShowDeleteJob ] = useState(false)
    const [ queryData, setQueryData ] = useState(initialState)

    const handleDeleteTrigger = () => {
        setShowDeleteJob(true)
        setQueryData({...queryData,
            bookId: bookId,
            sheetId: sheetId,
            taskId: taskId,
            triggerId: triggerId
        })
    }

    const handleDeleteTriggerQuery = () => {
        setIsLoad(true)
        fetch(`/api/jobs/deleteTrigger`, { method: 'DELETE', body: JSON.stringify(queryData), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(data => {
                setQueryData(initialState)
                setShowDeleteJob(false)
                callback()
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Trigger Deleted!' })
                setIsLoad(false)
            }
        )
    }

    return(
        <div className=''>
            <Alert className='bp4-dark' icon='trash' intent='danger' canEscapeKeyCancel={true} canOutsideClickCancel={true}
                isOpen={showDeleteJob}
                onClose={ () => setShowDeleteJob(false) }
                cancelButtonText='Cancel'
                onCancel={ () => setShowDeleteJob(false) }
                confirmButtonText='Yes'
                onConfirm={handleDeleteTriggerQuery}
            >
                <div className='delete-job-label-container'>
                    <span>Delete Trigger: </span>
                    <span className='delete-job-label'>{name} </span>?
                </div>
            </Alert>
            <Button className='delete-job-button' icon='trash' small={true} intent='danger'
                onClick={handleDeleteTrigger} 
                loading={isLoad}
                disabled={triggerIndex === otherData.selectedTriggerIndex && (checkChangesDescription || checkChangesSourceRange)}
            ></Button>
        </div>
    )
}

export default DeleteTrigger