import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Alert, Divider } from '@blueprintjs/core'
import { AppToaster } from '../../../utilities/Toaster/Toaster'

const DeleteWorkspace = ({ bookData }) => {
    const navigate = useNavigate()
    const [showDeleteWorkspaceModal, setShowDeleteWorkspaceModal] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)

    const handleDeleteWorkspace = (bookId) => {
        fetch(`/api/jobs/dropWorkspaceById/${bookId}`, { method: 'DELETE' })
            .then(res => res.json())
            .then(() => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Workbook successfully deleted!' })
                setShouldUpdate(!shouldUpdate)
                setShowDeleteWorkspaceModal(false)

                navigate('/jobs-automation')
            }
        )
    }

    return (
        <div>
            <Alert
                className='bp4-dark'
                icon='th-disconnect'
                intent='danger'
                canEscapeKeyCancel={true}
                canOutsideClickCancel={true}
                isOpen={showDeleteWorkspaceModal}
                onClose={ () => setShowDeleteWorkspaceModal(false) }
                cancelButtonText='Cancel'
                onCancel={ () => setShowDeleteWorkspaceModal(false) }
                confirmButtonText='Yes'
                onConfirm={ () => handleDeleteWorkspace(bookData.bookId) }
            >
                <p>Are you sure that you want to delete this workspace from the system?</p>
                <p style={{ fontWeight: 'bold', color: '#FFA726', fontSize: '18px' }}>{bookData.bookName}</p>

                <Divider />
                <p>Here’s what’ll happen when you delete a workspace:</p>
                <ul className='delete-workspace-reasons-ul'>
                    <li>The workspace will be totally removed from the Jobs Automation page and in our database.</li>
                    <li>All active sheets belongs to the workspace will also be deleted permanently.</li>
                    <li>Workbook used in creating the workspace will still be available in the <a href='http://localhost:3000/google-spreadsheets'>Google Spreadsheets</a> table.</li>
                </ul>

                <p style={{ fontWeight: 'bold', color: '#F44336' }}>This cannot be undone!</p>
            </Alert>
            <Button
                icon='th-disconnect'
                text='Delete workspace'
                intent='danger'
                style={{ float: 'right' }}
                onClick={ () => setShowDeleteWorkspaceModal(true) }
            />
        </div>
    )
}

export default DeleteWorkspace