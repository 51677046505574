import React, { useState } from 'react'
import { Alert, Button } from '@blueprintjs/core'
import { AppToaster } from '../../../utilities/Toaster/Toaster'

import './Sheets.css'

const DeleteSheet = ({ sheetId, bookId, sheetName, callback }) => {
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [showDeleteSheet, setShowDeleteSheet] = useState(false)
    
    const handleDeleteSheet = () => {
        fetch('/api/jobs/removeSheet', { method: 'PUT', body: JSON.stringify({ bookId: bookId, sheetId: sheetId }), headers: { 'Content-Type': 'application/json' } })
            .then(res => res.json())
            .then(() => {
                AppToaster.show({ icon: 'info-sign', intent: 'success', message: 'Sheet disconnected!' })
                setShouldUpdate(!shouldUpdate)
                setShowDeleteSheet(false)
                callback()
            })
    }

    return(
        <div>
            <Alert className='bp4-dark' icon='backlink' intent='warning' canEscapeKeyCancel={true} canOutsideClickCancel={true}
                isOpen={showDeleteSheet}
                onClose={() => setShowDeleteSheet(false)}
                cancelButtonText='Cancel'
                onCancel={() => setShowDeleteSheet(false)}
                confirmButtonText='Yes'
                onConfirm={() => handleDeleteSheet()}
            >
                <p>Are you sure you want to disconnect this sheet from the workspace?</p>
                <p style={{ fontWeight: 'bold' }}>{sheetName}</p>
            </Alert>
            <Button
                icon='backlink'
                intent='warning'
                onClick={() => setShowDeleteSheet(true)}
            >Disconnect</Button>
        </div>
    )
}

export default DeleteSheet